<ng-component *ngIf="loading$ | async; then loading; else loaded"></ng-component>
<ng-template #loading>
  <h3>Loading profile data</h3>
</ng-template>
<ng-template #loaded>
  <div class="c-report-wrapp">
    <div class="c-report-preview-pages u-margin-horizontal-auto">
      <div class="c-report-preview__page">
        <div class="graph-div">
          <h2>Body composition</h2>
          <div id="body_composition_container" class="o-flex o-flex--gap-xxs graph-div">
            <div class="u-1/4">
              <canvas id="humanBodyCtx"
                      class="u-1/1 u-border"></canvas>
            </div>
            <div class="u-1/4">
              <div id="body_composition"
                    class="pie"></div>
            </div>
            <div class="u-1/2">
              <table class="table borderless u-font-size-small"
                      id="table-composition"
                      aria-describedby="table-composition">
                <tbody>
                <tr>
                  <th scope="col">Body Mass</th>
                  <td>{{ bodyComposition.body_mass }}</td>
                </tr>
                <tr>
                  <th scope="col">Body Height</th>
                  <td>{{ bodyComposition.body_height }}</td>
                </tr>
                <tr>
                  <th scope="col">Body Mass <br>Index</th>
                  <td>{{ bodyComposition.body_mass_index }}</td>
                </tr>
                <tr>
                  <th scope="col">Projected BSA</th>
                  <td>{{ bodyComposition.projected_BSA }}</td>
                </tr>
                <tr>
                  <th scope="col">Body Fat</th>
                  <td>{{ bodyComposition.body_fat }}</td>
                </tr>
                <tr>
                  <th scope="col">Fat Free</th>
                  <td>{{ bodyComposition.fat_free }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="graph-div">
          <h2>Metabolic fingerprint</h2>
          <div id="metabolic_fingerprint" class="u-1/1">
              <div class="water-mark"></div>
              <canvas id="metabolic_fingerprint_radar" class="u-1/1"></canvas>
          </div>
        </div>
        <div class="graph-div">
          <h2>Performance development</h2>
          <div id="performance_development"></div>
        </div>
        <div class="graph-div">
          <h2>Physiological performance benchmarks</h2>
          <div class="o-flex o-flex--wrap o-flex--justify-space-around o-flex--middle o-flex--gap-xxs c-chart-gauge u-height-100">
            <div class="water-mark-wrapper water-mark"></div>
            <div class="u-1/4 c-chart-gauge__item"
                *ngFor="let data of gaugeChartData; index as i">
              <div class="c-chart-gauge__title u-margin-bottom-s u-line-clamp-2"
                  [innerHTML]="data.title"></div>
              <canvas id="gauge-{{ i }}"></canvas>
              <div class="c-chart-gauge__value">
                <span *ngIf="data.is_heart_rate">
                  <strong>HR</strong>
                  <sub>max</sub>
                  :&nbsp;
                </span>
                <strong class="u-margin-right-xxs">{{ data.value }}</strong>
                <small>{{ data.unit }}</small>
              </div>
              <div class="item-value-alternative">
                <span *ngIf="data.is_heart_rate">
                  <strong>PWC 150</strong>
                  :&nbsp;
                </span>
                <strong class="u-margin-right-xxs">{{ data.value_alternative_1 }}</strong>
                <small>{{ data.unit_alternative_1 }}</small>
              </div>
              <div class="item-value-alternative">
                <strong class="u-margin-right-xxs">{{ data.value_alternative_2 }}</strong>
                <small>{{ data.unit_alternative_2 }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="graph-div">
          <h2>Load characteristics</h2>
          <app-metabolic-profile *ngIf="metabolicProfileData"
                                 [chartData]="metabolicProfileData"
                                 [selectedTest]="testData"
                                 [external]="true"></app-metabolic-profile>
        </div>

        <!-- Test data graphs-->
        <div class="graph-div">
          <h2>Test Data Graphs</h2>
          <div id="test_data" [style.width.%]="100"></div>
        </div>
        <div class="graph-div">
          <h2>Test Data Tables</h2>
          <ng-container *ngIf="measuredValues.length > 0">
            <table class="u-font-size-small [ c-table ] o-flex--full u-margin-bottom"
                   aria-describedby="measured value">
              <tr>
                <th scope="col"
                    class="measured_values_header">Type</th>
                <th scope="col"
                    class="measured_values_header">
                  {{ isSpeed ? "Average Speed" : "Average Power" }}
                </th>
                <th scope="col"
                    class="measured_values_header">Duration</th>
                <th scope="col"
                    class="measured_values_header">Additional Value</th>
              </tr>
              <tr *ngFor="let measuredValue of measuredValues">
                <td [innerHTML]="measuredValue.type"></td>
                <td>
                  {{ isSpeed
                    ? measuredValue.speed
                      ? measuredValue.speed + "m/s"
                      : ""
                    : measuredValue.power
                      ? measuredValue.power + "W"
                      : ""}}
                </td>
                <td>{{ measuredValue.duration }}</td>
                <td [innerHTML]="measuredValue.additionalValueString"></td>
              </tr>
            </table>
            <table class="u-font-size-small [ table table--small ] weighted_values_table o-flex--full u-margin-bottom"
                   aria-describedby="weighted value">
              <tr>
                <th scope="col"
                    class="measured_values_header"
                    colspan="3">
                  Weighted Regression
                </th>
              </tr>
              <tr>
                <td>
                  <strong>VLa<sub>max</sub></strong>
                </td>
                <td>
                  <strong>VO<sub>2max</sub></strong>
                </td>
                <td><strong>Anaerobic Threshold</strong></td>
              </tr>
              <tr>
                <td>{{ weightedValues.vl_weight }}</td>
                <td>{{ weightedValues.vo_weight }}</td>
                <td>{{ weightedValues.at_weight }}</td>
              </tr>
            </table>
          </ng-container>
          <ng-container *ngIf="rawTestDataTable">
            <table id="rawTestDataTable"
                   border="0"
                   class="u-font-size-small c-table o-flex--full u-margin-bottom"
                   aria-describedby="tabel">
              <thead>
              <tr>
                <th id="testdatheadertable"
                    *ngFor="let header of rawTestDataTable.header"
                    [attr.colspan]="header.colspan">
                  {{ header.label }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let raw_data of rawTestDataTable.data">
                <td *ngFor="let value of raw_data">{{ value }}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-container *ngIf="calculatedDataTable">
            <table id="calculatedDataTable"
                   border="0"
                   class="c-table u-font-size-small o-flex--full"
                   aria-describedby="tabel">
              <thead>
              <tr>
                <th id="calculateddatheadertable"
                    *ngFor="let header of calculatedDataTable.header"
                    [attr.colspan]="header.colspan">
                  {{ header.label }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let raw_data of calculatedDataTable.data">
                <td *ngFor="let value of raw_data">{{ value }}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
        <div class="graph-div">
          <h2>Training zone</h2>
          <div *ngIf="!trainingZoneTableData || (trainingZoneTableData && trainingZoneTableData.length === 0)"
               id="training_zones"
               class="u-font-size-xsmall o-flex--self-start"
               [innerHTML]="trainingZoneTableHtml"></div>

          <ng-container *ngIf="trainingZoneTableData && trainingZoneTableData.length > 0"
               class="">
            <table class="u-1/1 c-table u-font-size-xsmall o-flex--self-start" aria-describedby="training zone">
              <tr>
                <th scope="row">Zone</th>
                <th scope="row">Code</th>
                <th [colSpan]="maxLowUpTarget" scope="row">
                  Lower
                </th>
                <th [colSpan]="maxLowUpTarget" scope="row">
                  Upper
                </th>
                <th [colSpan]="maxLowUpTarget" scope="row">
                  Target
                </th>
                <th [colSpan]="maxLowUpTarget" scope="row">
                  Physiological Conditions
                </th>
              </tr>
              <tr style="visibility: collapse">
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row" [colSpan]="getMaxOutputValues()">
                  &nbsp;
                </th>
              </tr>
              <tr *ngFor="let zone of trainingZoneTableData; let zoneIndex = index">
                <td *ngIf="zone.name.value">{{ zone.name.value }}</td>
                <td *ngIf="zone.code.value">{{ zone.code.value }}</td>
                <td [colSpan]="zone.lower.colspan"
                    *ngFor="let header of zone.lower.headers; let i = index">
                  <span class="title-zone">{{ header }}</span> <br />
                  <strong>{{ zone.lower.values[i] }}</strong><br />
                  <span class="title-zone">{{ zone.lower.units[i] }}</span> <br />
                </td>
                <td [colSpan]="zone.lower.colspan"
                    *ngFor="let header of zone.upper.headers; let i = index">
                  <span class="title-zone">{{ header }}</span> <br />
                  <strong>{{ zone.upper.values[i] }}</strong><br />
                  <span class="title-zone">{{ zone.upper.units[i] }}</span> <br />
                </td>
                <td [colSpan]="zone.lower.colspan"
                    *ngFor="let header of zone.target.headers; let i = index">
                  <div *ngIf="zone.name.value !== appConstant.LACTATE_SHUTTLING && zone.target.values[i] !== '0'">
                    <span class="title-zone">{{ header }}</span> <br />
                    <strong>{{ zone.target.values[i] }}</strong><br />
                    <span class="title-zone">{{ zone.target.units[i] }}</span> <br />
                  </div>
                </td>
                <td [style.width]="getWidthOutputValue()"
                    *ngFor="let header of zone.output_values.headers; let i = index">
                  <span class="title-zone">{{ header }}</span> <br />
                  <strong>{{ zone.output_values.values[i] }}</strong><br />
                  <span class="title-zone">{{ zone.output_values.units[i] }}</span>
                  <br />
                </td>
              </tr>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
