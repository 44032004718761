import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable, Subject } from 'rxjs';
import { PaymentLinkDialogDataInterface } from '../interfaces/payment-link-dialog-data.interface';
import { PaymentLinkInterface } from '../interfaces/payment-link.interface';
import { PaymentLinkState } from './payment-link-dialog.state';

@Component({
  selector: 'app-payment-link-dialog',
  templateUrl: './payment-link-dialog.component.html',
  styleUrls: ['./payment-link-dialog.component.scss'],
  providers: [PaymentLinkState]
})
export class PaymentLinkDialogComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  public loading$: Observable<boolean>;

  public paymentLinkForm!: FormGroup;
  public paymentLink: PaymentLinkInterface;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PaymentLinkDialogDataInterface,
    public dialogRef: MatDialogRef<PaymentLinkDialogComponent>,
    private fb: FormBuilder,
    private state: PaymentLinkState
  ) { }

  ngOnInit(): void {
    this.loading$ = this.state.loading$;
    this.state.paymentLink$.subscribe((paymentLink: PaymentLinkInterface) => {
      this.paymentLink = paymentLink;
    })
    this.initForm();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public cancel() { this.dialogRef.close([false, undefined]); }

  public sendEmail() {
    this.state.emailPaymentUrl(this.data.planId, this.paymentLink, this.paymentLinkForm.get("email")?.value)
      .subscribe(() => {
        this.dialogRef.close([true, undefined]);
      })
  }

  public generatePaymentLink() {
    this.state.generatePaymentLink(this.data.planId);
  }

  private initForm() {
    this.paymentLinkForm = this.fb.group({
      email: new FormControl<string>("", [Validators.email, Validators.required])
    })
  }
}
