import { PagedResultDto } from "@shared/components/paged-listing-component-base";
import { Deserializable } from "@shared/models/deserializable";

export class PricingPlansResultDto extends PagedResultDto {
  public items: PricingPlansDto[] = [];
}

export class PricingPlansDto extends Deserializable {
  public id: number | undefined;
  public link_items:
    |
        Array<{
            activation_items: any | undefined;
            lots: any | undefined;
        }>
    | undefined;
  public display_items: string | undefined;
  public alternative_items:
    |
       Array<{
        id: number | undefined;
        master_item: number | undefined;
        alternative_item: string | undefined;
        created_at: string | undefined;
        updated_at: string | undefined;
        is_delete: boolean | undefined;
        plan: number | undefined;
        }>
    | undefined;
  public  discounts: | Array<{}> | undefined;
  public ratios: | Array<{}> | undefined;
  public lots:
    |
        Array<{
            id: number | undefined;
            lot_prices:
            | Array<{
                id: number | undefined;
                type: number | undefined;
                user: number | undefined;
                athlete: number | undefined;
                test_la: number | undefined;
                test_map: number | undefined;
                test_manual: number | undefined;
                test_virtual: number | undefined;
                test_event: number | undefined;
                test_result: number | undefined;
                link_item: number | undefined;
                created_at: string | undefined;
                updated_at: string | undefined;
                is_delete: boolean | undefined;
                region: number | undefined;
                lot: number | undefined;
            }>
            | undefined;
            from_link_item: number | undefined;
            from_user: number | undefined;
            from_athlete: number | undefined;
            from_test_la: number | undefined;
            from_test_map: number | undefined;
            from_test_manual: number | undefined;
            from_test_virtual: number | undefined;
            from_test_event: number | undefined;
            from_test_result: number | undefined;
            to_link_item: number | undefined;
            to_user: any | undefined;
            to_athlete: any | undefined;
            to_test_la: any | undefined;
            to_test_map: any | undefined;
            to_test_manual: any | undefined;
            to_test_virtual: any | undefined;
            to_test_event: any | undefined;
            to_test_result: any | undefined;
            is_once_link_item: boolean | undefined;
            is_once_user: boolean | undefined;
            is_once_athlete: boolean | undefined;
            is_once_la: boolean | undefined;
            is_once_map: boolean | undefined;
            is_once_manual: boolean | undefined;
            is_once_virtual: boolean | undefined;
            is_once_event: boolean | undefined;
            is_once_result: boolean | undefined;
            created_at: string | undefined;
            updated_at: string | undefined;
            is_delete: boolean | undefined;
            plan: number | undefined;
            link: any | undefined;
        }>
    | undefined;
  public plan_prices:
    |
        Array<{
            id: number | undefined;
            annual: number | undefined;
            quarter: number | undefined;
            month: number | undefined;
            excess: number | undefined;
            created_at: string | undefined;
            updated_at: string | undefined;
            is_delete: boolean | undefined;
            region: number | undefined;
            plan: number | undefined;
        }>
    | undefined;
  public group:
    |
        {
            id: number | undefined;
            name: string | undefined;
            created_at: string | undefined;
            updated_at: string | undefined;
            is_delete: boolean | undefined;
        }
    | undefined;
  public name: string | undefined;
  public description: string | undefined;
  public features: string | undefined;
  public product_ids: any | undefined;
  public bundled_features: any | undefined;
  public start_date_contract: string | undefined;
  public is_linked_product: boolean | undefined;
  public renewal: any | undefined;
  public renewal_unit: any | undefined;
  public cancel_notice_time: number | undefined;
  public minimum_contract_duration: number | undefined;
  public notification_user: any | undefined;
  public notification_athlete: any | undefined;
  public notification_test_la: number | undefined;
  public notification_test_map: number | undefined;
  public notification_test_manual: any | undefined;
  public notification_test_virtual: any | undefined;
  public notification_test_event: any | undefined;
  public notification_test_result: any | undefined;
  public max_user: number | undefined;
  public max_athlete: number | undefined;
  public max_test_la: number | undefined;
  public max_test_map: number | undefined;
  public max_test_manual: number | undefined;
  public max_test_virtual: number | undefined;
  public max_test_event: number | undefined;
  public max_test_result: number | undefined;
  public additional_user_price: any | undefined;
  public additional_test_price: any | undefined;
  public additional_athlete_price: any | undefined;
  public is_publish: boolean | undefined;
  public is_accumulated: boolean | undefined;
  public is_system: boolean | undefined;
  public include_items: string | undefined;
  public created_at: string | undefined;
  public updated_at: string | undefined;
  public is_delete: boolean | undefined;
  public account_types: | Array<{}> | undefined;
  public regions: | Array<number> | undefined;
  public organizations: | Array<number> | undefined;
  public billing_period_details: Array<BillingPeriodDetail> | undefined;
  public right_management: string | undefined;
  public is_show_setting: boolean | undefined;

  getActivity(): string {
    let value = "";
    if(this.is_publish == true) {
        value = "Yes"
    } else if(this.is_publish == false) {
        value = "No"
    }
    return value;
  }
}

export interface BillingPeriodDetail {
  id: number | undefined;
  renewal: any | undefined;
  renewal_unit: any | undefined;
  cancel_notice_time: number | undefined;
  minimum_contract_duration: number | undefined;
  max_user: number | undefined;
  max_athlete: number | undefined;
  max_test_la: number | undefined;
  max_test_map: number | undefined;
  max_test_manual: number | undefined;
  max_test_virtual: number | undefined;
  max_test_event: number | undefined;
  max_test_result: number | undefined;
  period_type: number | undefined;
  plan: number | undefined;
}

export class GroupResultDto extends PagedResultDto {
  public items: GroupDto[] = [];
}

export class GroupDto extends Deserializable {
  public id: number | undefined;
  public name: string | undefined;
  public created_at: string| undefined;
  public updated_at: string | undefined;
  public is_delete: boolean | undefined;
 }
