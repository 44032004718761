import { ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { AppConstants } from '@core/constants';
import { ApiService } from "@core/services/api.service";
import { SessionService } from '@core/services/session.service';
import { ICountryInterface } from '@shared/components/interfaces/country.interface';
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { map } from 'rxjs/operators';

const DATASETS = [{
  "slug": "swimming",
  "name": "Swimming",
}, {
  "slug": "cycling",
  "name": "Cycling",
}, {
  "slug": "triathlon",
  "name": "Triathlon",
}, {
  "slug": "running",
  "name": "Running",
}, {
  "slug": "canoeing",
  "name": "Canoeing",
}, {
  "slug": "rowing",
  "name": "Rowing",
}, {
  "slug": "xc_ski",
  "name": "XC Ski",
}, {
  "slug": "speed_skating",
  "name": "Speed Skating",
}, {
  "slug": "team_sports",
  "name": "Team Sports",
}]

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss'],
})
export class AdditionalInfoComponent implements OnInit {
  public additionalInfoForm!: UntypedFormGroup;
  public sports: any = [
    { name: 'Cycling', id: 0 },
    { name: 'Running', id: 1 },
    { name: 'Swimming', id: 2 },
    { name: 'Triathlon', id: 3 },
    { name: 'Skating', id: 4 },
    { name: 'Skiing', id: 8 },
    { name: 'Volleyball', id: 5 },
    { name: 'Football', id: 6 },
    { name: 'Basketball', id: 7 },
  ];
  public professions: any = [
    { id: 0, name: 'Athlete' },
    { id: 1, name: 'Coach' },
    { id: 2, name: 'Scientist' },
    { id: 3, name: 'Lab owner' },
    { id: 4, name: 'Working professional' },
  ];
  public countryList: Array<ICountryInterface> = [];
  public isAdditionalInfoSubmitted: boolean = false;
  public datasetList: Record<string, any>[] = DATASETS;
  public orgLogo: any;
  public showInvoiceAddressForm = false;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private router: Router,
    private customRendererService: CustomRendererService,
    private destroyRef: DestroyRef,
    private sessionService: SessionService,
    private readonly cdr: ChangeDetectorRef,
    ) {}

  public initForm(): void {
    this.additionalInfoForm = this.fb.group({
      organizationName: ['', Validators.required],
      photo: [null],
      vatNumber: [''],
      address: [null],
      invoiceAddress: [{value: null, disabled: true}],
      sameInvoiceAddress: [true],
      datasets: [],
    });

    this.additionalInfoForm.get('sameInvoiceAddress')?.valueChanges.subscribe((newValue: boolean) => {
      this.toggleInvoiceAddressForm(newValue);
    })
  }
  public ngOnInit(): void {
    this.customRendererService.removeClass('body', ['light-theme']);
    this.customRendererService.addClass('body', ['dark-theme']);
    this.initForm();
    this.countryList = this.activatedRoute.snapshot.data['countries'];
  }

  public submit(): void {
    this.customRendererService.show('.loading_layout');
    const apiUrl = AppConstants.ACCOUNTS_URL.ACCOUNTS + AppConstants.ACCOUNTS_URL.POST.ADDITIONAL_INFO;
    this.apiService.xhrCall(apiUrl, this.processFormData(), 'POST')
      .pipe(
        map((response: any) => {
          return JSON.parse(response.response)
        })
      ).subscribe({
        next: (res: any): void => {
          this.customRendererService.hide('.loading_layout');
          // Update session
          const auth: Record<string, any> = JSON.parse(this.sessionService.getCookie('auth') || '{}');
          // Prevent guard from blocking the dashboard
          auth.account_setup_required = false;
          // New organization information
          try {
            auth.organization.name = res?.organization_name ?? auth.organization.name;
            auth.organization.photo = res?.photo ?? auth.organization.photo;
            auth.organization.address_country_id = res?.address_country_id ?? auth.organization.address_country_id;
          } catch (error: any) {
            console.error(`Unable to update session org details: ${error}`)
          }
          this.sessionService.setCookie('auth', JSON.stringify(auth));
          this.router.navigate(['/']);
        },
        error: error => {
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          this.customRendererService.hide('.loading_layout');
        }
      })
  }

  public onFilePicked(event: any): void {
    const files: File[] = event.target.files;
    if (files.length > 1 || !files.length) {
      event.target.value = '';
    }
    const readFile = async (file: File): Promise<void> => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.additionalInfoForm.controls.photo.setValue(e.target?.result);
        this.cdr.detectChanges();
      };
      reader.readAsDataURL(file);
    };
    if (files.length) {
      if (files[0]?.size <= AppConstants.ORGANIZATION_PHOTO_MAX_SIZE) {
        this.orgLogo = files[0];
        readFile(files[0]);
      } else {
        event.target.value = '';
        this.additionalInfoForm.controls.photo.setValue('');
        this.cdr.detectChanges();
        this.snackBar.open('Photo size must be less than 100KB', 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    }
  }

  public logout() {
    this.apiService.post('accounts/logout/', {})
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((): void => {
      localStorage.clear();
      this.sessionService.deleteAllCookies();
      window.location.href = '/';
    });
  }

  public toggleInvoiceAddressForm(disable: boolean) {
    this.showInvoiceAddressForm = !disable;
    if (disable) {
      this.additionalInfoForm.get('invoiceAddress')?.disable();
    } else {
      this.additionalInfoForm.get('invoiceAddress')?.enable();
    }
  }

  private processFormData(): Record<string, any> {
    const form = this.additionalInfoForm;
    const addressFormData = this.additionalInfoForm.get('address')?.value;
    let invoiceAddressFormData = this.additionalInfoForm.get('invoiceAddress')?.value;
    if (!invoiceAddressFormData) {
      invoiceAddressFormData = addressFormData;
    }
    if (form.get('sameInvoiceAddress')?.value) {
      // Override invoice address details
      invoiceAddressFormData = addressFormData;
    }
    
    const processedFormData: Record<string, any> = {
      organization_name: form.get('organizationName')?.value,
      address_street_name: addressFormData?.streetName,
      address_street_number: addressFormData?.streetNumber,
      address_zip: addressFormData?.zip,
      address_city: addressFormData?.city,
      address_state: addressFormData?.state,
      address_country_id: addressFormData?.country,
      invoice_org_name: form.get('organizationName')?.value,
      invoice_address_street_name: invoiceAddressFormData?.streetName,
      invoice_address_street_number: invoiceAddressFormData?.streetNumber,
      invoice_address_zip: invoiceAddressFormData?.zip,
      invoice_address_city: invoiceAddressFormData?.city,
      invoice_address_state: invoiceAddressFormData?.state,
      invoice_country_id: invoiceAddressFormData?.country,
      country_id: addressFormData?.country,
      is_use_invoice_address: !form.get('sameInvoiceAddress')?.value,
      vat_number: form.get('vatNumber')?.value,
      invoice_vat_number: form.get('vatNumber')?.value,
      photo: this.orgLogo ?? undefined,
      datasets: form.get("datasets")?.value,
    }
    return processedFormData;
  }
}
