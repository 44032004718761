<div *ngIf="!isLoggedIn" class="full-page-container">
  <div class="o-wrapper o-wrapper--tiny form" >
      <figure class="c-hero-logo">
        <img alt="INSCYD Logo"
             src="../../../../assets/images/app_logo.png"/>
      </figure>
      <div class="field" [formGroup]="loginForm">
        <mat-form-field appearance="outline" [style.width.px]="350">
          <input
            class="loginInput"
            matInput
            placeholder="Username"
            formControlName="username"
            (keyup.enter)="login()"
          />
        </mat-form-field>
      </div>
      <div class="field" [formGroup]="loginForm">
        <mat-form-field appearance="outline" [style.width.px]="350">
          <input

            class="loginInput"
            type="password"
            matInput
            placeholder="Password"
            formControlName="password"
            (keyup.enter)="login()"
          />
        </mat-form-field>
      </div>
      <button class="c-btn-blue u-margin-bottom-s"
              type="submit"
              (click)="login()">Log In</button>
      <a class="forgot-password-link"
         [routerLink]="['forgot-password']">Forgot password?</a>
  </div>
</div>
