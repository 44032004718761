<div id="loading" class="loading_layout" style="display: none">
  <div
    id="loading_wrapper"
    class="loading_wrapper"
    title="Click to hide loading"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="full-page-container u-screen-height o-flex">
  <div class="o-wrapper">
    <figure class="c-hero-logo">
      <img  alt="INSCYD Logo"
            src="../../../../assets/images/app_logo.png"/>
    </figure>
    <div class="o-flex">
      <div class="o-flex--full">
        <h2 class="u-color-white font-size-large">Sign in</h2>
        <div class="o-flex o-flex--column o-flex--gap-1"
             [formGroup]="signInForm">
          <mat-form-field class="mat-input--flush u-color-white"
                          appearance="outline">
            <input matInput
                   placeholder="Username"
                   formControlName="username"
                   required/>
          </mat-form-field>
          <ng-container *ngIf="(signInForm.touched || signInForm.dirty) && signInForm.invalid">
            <mat-error *ngIf="signInForm.get('username')?.errors?.required">Username is required</mat-error>
          </ng-container>

          <mat-form-field class="u-color-white mat-input--flush"
                          appearance="outline">
            <input matInput
                   [attr.type]="showPassSignIn ? 'text' : 'password'"
                   placeholder="Password"
                   formControlName="password"
                   required/>
            <mat-icon matSuffix
                      class="u-cursor-pointer"
                      (click)="onShowPassword(0, $event)">remove_red_eye</mat-icon>
          </mat-form-field>
          <ng-container *ngIf="(signInForm.touched || signInForm.dirty) && signInForm.invalid">
            <mat-error *ngIf="signInForm.get('password')?.errors?.required">Password is required</mat-error>
          </ng-container>
          <button class="u-cursor-pointer font-size-16 u-background-light-blue u-padding u-color-white u-border-radius-l u-border-none u-margin-top"
                  (click)="login()">
            Sign In and Confirm Payment
          </button>
          <a class="u-color-white font-size-16 u-padding-top-xxs"
             style="text-decoration: none"
             [routerLink]="['forgot-password']">Forgot password?</a>
        </div>
      </div>
      <mat-divider class="u-background-white u-margin-horizontal-80" vertical></mat-divider>
      <div class="o-flex--full">
        <div *ngIf="isRegistrationSubmitted" class="registration-submitted">
          <mat-icon class="u-color-white">done</mat-icon>
          <span class="u-color-white">We have sent the letter on your email, check and complete registration, please</span>
        </div>
        <div *ngIf="clientSecret" class="client-secret">
          <h2 class="u-color-white font-size-large">Payment Details</h2>
          <ngx-stripe-elements
            [stripe]="stripe"
            [elementsOptions]="elementsOptions"
          >
            <ngx-stripe-payment />
          </ngx-stripe-elements>
          <button class="u-cursor-pointer font-size-16 u-background-light-blue u-width-350 u-padding u-color-white u-border-radius-l u-border-none"
                  (click)="addPaymentMethod()">
            Confirm
          </button>
          <div class="stripe-element-text">
            You will still have the opportunity to review your purchase before buying it.
          </div>
        </div>
        <div *ngIf="!isRegistrationSubmitted && !clientSecret" class="registration-form">
          <h2 class="u-color-white font-size-large">Register</h2>
          <div class="o-flex o-flex--column u-margin-bottom-m"
               [formGroup]="registrationForm">
            <mat-form-field class="mat-input--flush u-color-white"
                            appearance="outline">
              <input matInput
                     placeholder="First name"
                     formControlName="first_name"
                     required/>
            </mat-form-field>
            <ng-container *ngIf="(firstNameControl?.touched || firstNameControl?.dirty) && firstNameControl?.invalid">
              <mat-error *ngIf="firstNameControl?.errors?.required">First name is required</mat-error>
            </ng-container>
            <mat-form-field
              class="mat-input--flush u-color-white"
              appearance="outline">
              <input matInput
                     placeholder="Last name"
                     formControlName="last_name"
                     required/> </mat-form-field>
            <ng-container *ngIf="(lastNameControl.touched || lastNameControl.dirty) && lastNameControl.invalid">
              <mat-error *ngIf="lastNameControl?.errors?.required">Last name is required</mat-error>
            </ng-container>

            <mat-form-field class="mat-input--flush u-color-white"
                            appearance="outline">
              <input matInput
                     placeholder="Email address"
                     formControlName="email"
                     required/>
            </mat-form-field>

            <div class="consent-accepted-checkbox">
              <label>
                <mat-checkbox color="primary" formControlName="consent_accepted" name="documents" data-cons-preference="legal_documents"></mat-checkbox>
                <span class="privacy-terms-text">I've read the <a class="hyper-link-color" href="#" target="_blank" [routerLink]="['/privacy-policy']">Privacy Policy</a> and accept the <a class="hyper-link-color" href="#" target="_blank" [routerLink]="['/terms']">Terms and Condition</a></span>
              </label>
            </div>

            <ng-container *ngIf="(emailControl.touched || emailControl.dirty) && emailControl.invalid">
              <mat-error *ngIf="emailControl?.errors?.email">Email format not valid</mat-error>
              <mat-error *ngIf="emailControl?.errors?.required">Email is required</mat-error>
            </ng-container>
          </div>
          <button class="u-cursor-pointer font-size-16 u-background-light-blue u-width-350 u-padding u-color-white u-border-radius-l u-border-none"
                  (click)="register()"
                  [disabled]="registrationForm.invalid">
            Register
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
