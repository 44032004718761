<div *ngIf="isLoading" id="loading" class="loading_layout">
  <div
    id="loading_wrapper"
    class="loading_wrapper"
    title="Click to hide loading"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="full-page-container">
  <div class="shared-data-container" *ngIf="isConfirmSharedDataFormAccessible">
    <div class="text-message-box">
      <h4 class="additional-text">{{organizationName ? organizationName : 'Unknown organization'}} is requesting access to your INSCYD test data. Do you want to share your data?</h4>
      <div class="accept-decline-buttons">
        <button mat-flat-button class="c-btn-red" (click)="sendShareStatus()">Decline</button>
        <button mat-flat-button class="c-btn-green" (click)="sendShareStatus(true)">Accept</button>
      </div>
    </div>
  </div>
  <div *ngIf="showThanksPage" class="shared-data-submitted shared-data-container">
    <div class="thanks-message">
      <mat-icon class="u-color-white">done</mat-icon>
      <span class="u-color-white">Thank you for an answer</span>
    </div>
  </div>
</div>
