import { Component, DestroyRef, ElementRef, EventEmitter, Input, Output, ViewChild, } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { AthleteDetailDialogComponent } from '@shared/dialogs/athlete-detail-dialog/athlete-detail-dialog.component';
import { CreateEventDialogComponent } from '@shared/dialogs/create-event-dialog/create-event-dialog.component';
import { CreateTeamDialogComponent } from '@shared/dialogs/create-team-dialog/create-team-dialog.component';
import { TestDialogComponent } from '@shared/dialogs/test-dialog/test-dialog.component';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';
import { AppBaseComponent } from '../app-component-base';
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

enum pathValues {
  MANUAL = 'manual',
  VIRTUAL = 'virtual',
  PPD = 'newtype',
  LACTATE = 'lactate',
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends AppBaseComponent {
  public filteredOptions: Observable<string[]> | undefined;
  public allTags: any;
  public country: any;
  public allSports: any;
  public filteredAthletes: any;
  public themeSwitcher: FormControl<any> = new UntypedFormControl(true);
  public myControl: UntypedFormControl = new UntypedFormControl();
  public coaches: any;
  public searchText: string = '';
  public isDarkMode: boolean = false;

  @Input() public currentTestId: string = '';
  @Output() public menuBarChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(MatAutocompleteTrigger) public searchAutoComplete: | MatAutocompleteTrigger | undefined;
  @ViewChild('inputSearchText') public inputSearchText: | ElementRef<HTMLElement> | undefined;
  public v1Link: string = this.constant.V1_URL;
  public options: string[] = [
    'Search in athletes',
    'Search in teams',
    'Search in tests',
  ];

  constructor(
    private readonly _apiService: ApiService,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private router: Router,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  public onInitPage(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
    this.loadData();
    this.setDefaultTheme();
    this.handleReloadPage();
  }

  public openAthleteModalWindow(): void {
    this.dialog.open(AthleteDetailDialogComponent, {
      width: '600px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: { isShowDetail: true },
    }).afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res): void => {
      if (res) {
        this.snackBar.open(AppConstants.NOTIFICATIONS.ATHLETE_ADDED, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        this.router.navigate(['home', 'manage', 'athlete']);
      }
    });
  }

  public openTeamModalWindow(): void {
    this.dialog.open(CreateTeamDialogComponent, {
      width: '600px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: { isShowDetail: true },
    }).afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res): void => {
        if (res) {
          this.snackBar.open(AppConstants.NOTIFICATIONS.TEAM_ADDED, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          this.router.navigate(['home', 'tac', 'teams']);
        }
      });
  }

  public openEventModalWindow(): void {
    this.dialog.open(CreateEventDialogComponent, {
      width: '600px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: { isShowDetail: true },
    }).afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res): void => {
        if (res) {
          this.snackBar.open(AppConstants.NOTIFICATIONS.EVENT_ADDED, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          this.router.navigate(['home', 'dynamic-simulation', 'manage-event']);
        }
      });
  }

  private handleReloadPage(): void {
    const self = this;

    document.addEventListener('visibilitychange', (): void => {
      if (document.hidden) {
        setTimeout((): void => {
          const theme: string | null = localStorage.getItem('theme');
          const valueControl = self.themeSwitcher?.value;
          if (
            (theme === AppConstants.THEME.DARK && !valueControl) ||
            (theme === AppConstants.THEME.LIGHT && valueControl)
          ) {
            window.location.reload();
          }
        }, 5000);
      }
    });
  }

  private loadData(): void {
    const listApiRequest: Observable<Object>[] = [
      this.fetchAthletes(),
      this.fetchSports(),
      this.fetchTags(),
      this.fetchCoaches(),
    ];

    forkJoin(listApiRequest)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([filteredAthletes, allSports, allTags, coaches]: any): void => {
        this.filteredAthletes = filteredAthletes?.results;
        this.allSports = allSports?.results;
        this.allTags = allTags?.results;
        this.coaches = coaches?.results;
     });
  }

  private fetchTags(): Observable<Object> {
    return this._apiService.get('tags/all/');
  }

  private fetchCoaches(): Observable<Object> {
    return this._apiService.get('accounts/coaches/');
  }

  private fetchSports(): Observable<Object> {
    return this._apiService.get('sports/');
  }

  private fetchAthletes(): Observable<Object> {
    return this._apiService.get('athletes/list-all/');
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.options.filter((option: string): boolean => {
      return option.toLowerCase().indexOf(filterValue) === 0;
    });
    return this.options.filter(
      (option: string): boolean => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  public logout(): void {
    let selectedColorTheme: string = localStorage.getItem('theme') || '';
    this._apiService.post('accounts/logout/', {})
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((): void => {
      localStorage.clear();
      localStorage.setItem('theme', selectedColorTheme);
      this.sessionService.deleteAllCookies();
      window.location.href = '/';
    });
  }

  public  openHelpCenterLink(): void {
    window.open(AppConstants.HELP_CENTER.URL, '_blank');
  }

  private setDefaultTheme(): void {
    const theme: string | null = localStorage.getItem('theme');
    if (!theme) {
      localStorage.setItem('theme', AppConstants.THEME.DARK);
    }
    if (theme === AppConstants.THEME.DARK || !theme) {
      this.themeSwitcher.setValue(true);
      this.customRendererService.removeClass('body', ['light-theme']);
      this.customRendererService.addClass('body', ['dark-theme']);
      this.isDarkMode = true;
    } else if (theme === AppConstants.THEME.LIGHT) {
      this.themeSwitcher.setValue(false);
      this.customRendererService.removeClass('body', ['dark-theme']);
      this.customRendererService.addClass('body', ['light-theme']);
      this.isDarkMode = false;
    }
  }

  public changeTheme(): void {
    if (this.themeSwitcher?.value) {
      localStorage.setItem('theme', AppConstants.THEME.DARK);
    } else {
      localStorage.setItem('theme', AppConstants.THEME.LIGHT);
    }
    window.location.reload();
  }

  public toggleMenuBar(): void {
    this.menuBarChange.emit(true);
  }

  public isShowCreateButton() {
    return (
      (this.rights.athletes_create && !this.auth.role.is_owner) ||
      (this.rights.teams_list &&
        !this.auth.role.is_owner &&
        !this.auth.role.is_agent_role) ||
      (this.rights.dynamic_simulation_event_list &&
        !this.auth.role.is_owner &&
        !this.auth.role.is_agent_role) ||
      (!this.auth.role.is_owner &&
        (this.rights.test_create_critical_power_test ||
          this.rights.test_create_virtual ||
          this.rights.test_manual_test ||
          this.rights.test_create_lactate))
    );
  }

  public navigateToTests(pathValue: string): void {
    let queryParams: { create: string; test_id?: string } = {
      create: pathValue,
    };
    if (pathValue == 'virtual') {
      queryParams = {
        ...queryParams,
        test_id: this.currentTestId,
      };
    }
    this.router.navigate(['home/manage', 'tests'], { queryParams });
  }

  public openDialog(
    isManual: boolean,
    isPPD: boolean,
    isVirtual: boolean,
    isLactate: boolean
  ): void {
    let pathValue: string = '';
    if (isManual) {
      pathValue = pathValues.MANUAL;
    }
    if (isPPD) {
      pathValue = pathValues.PPD;
    }
    if (isLactate) {
      pathValue = pathValues.LACTATE;
    }
    if (isVirtual) {
      pathValue = pathValues.VIRTUAL;
    }

    this.navigateToTests(pathValue);

    const dialogRef: MatDialogRef<TestDialogComponent> = this.dialog.open(TestDialogComponent, {
      width: '50%',
      height: '95%',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: { isManual, isPPD, isVirtual, isLactate, testId: this.currentTestId },
    });

    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res): void => {
      if (res) {
        let message: string = 'Item created successfully';
        this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
      }
    });
  }

  public openManualTest(): void {
    this.openDialog(true, false, false, false);
  }

  public openPPDTest(): void {
    this.openDialog(false, true, false, false);
  }

  public openVirtualTest(): void {
    this.openDialog(false, false, true, false);
  }

  public openLactateTest(): void {
    this.openDialog(false, false, false, true);
  }
}
