<div *ngIf="isLoading" id="loading" class="loading_layout">
  <div
    id="loading_wrapper"
    class="loading_wrapper"
    title="Click to hide loading"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="full-page-container">
  <div class="form" *ngIf="isConsentFormAccessible; else invalidConsentForm">
    <div class="form-group text-center">
      <h1>We've updated our Privacy Policy and Terms and Conditions</h1>
      <h4 class="additional-text">
        At INSCYD , we value your privacy and know that transparency and trust are important. As part of continued efforts to ensure you can enjoy our products, services and websites, we’re updating our Privacy Policy and Terms and Conditions – further clarifying how we collect, use and share your personal data. These updates reflect changes in the Swiss Data Protection Law (revised Federal Data Protection Act). We invite you to review the full Privacy Policy and Terms and Conditions. To continue using our products, services and website you must acknowledge the Privacy Policy and accept the Terms and Conditions.
      </h4>
    </div>
    <form [formGroup]="form" action="" id="the-form" class="iub_cons_form">
      <input type="text" name="subject_id" [value]="subjectId" data-cons-subject="id" hidden/>
      <input type="text" formControlName="fullName" [value]="fullName" name="full_name" data-cons-subject="full_name" hidden/>
      <input type="text" name="verified" data-cons-subject="verified" value="true" hidden/>
      <p>
        <mat-form-field appearance="outline" [style.width.px]="350">
          <input
            formControlName="subjectFirstName"
            type="text"
            name="first_name"
            data-cons-subject="first_name"
            class="loginInput"
            matInput
            placeholder="First name"
            [readonly]="isSubjectFirstNameReadOnly"
          />
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="outline" [style.width.px]="350">
          <input
            formControlName="subjectLastName"
            type="text"
            name="last_name"
            data-cons-subject="last_name"
            class="loginInput"
            matInput
            placeholder="Last name"
            [readonly]="isSubjectLastNameReadOnly"
          />
        </mat-form-field>
        <mat-error *ngIf="form.get('subjectLastName')?.invalid">
          *required
        </mat-error>
      </p>
      <p>
        <mat-form-field appearance="outline" [style.width.px]="350">
          <input
            [value]="subjectEmail"
            type="text"
            formControlName="subjectEmail"
            name="email"
            data-cons-subject="email"
            class="loginInput"
            matInput
            placeholder="Email"
            [readonly]="isSubjectEmailReadOnly"
          />
        </mat-form-field>
      </p>
      <div class="legal-notices-checkbox">
        <label>
          <mat-checkbox color="primary" formControlName="legalNotices" name="documents" data-cons-preference="legal_documents"></mat-checkbox>
          <span class="privacy-terms-text">I've read the <a class="hyper-link-color" href="#" target="_blank" [routerLink]="['/privacy-policy']">Privacy Policy</a> and accept the <a class="hyper-link-color" href="#" target="_blank" [routerLink]="['/terms']">Terms and Condition</a></span>
          <mat-error *ngIf="form.get('legalNotices')?.invalid">
            *required
          </mat-error>
        </label>
      </div>
      <div class="consent-buttons">
        <button id="decline-btn" mat-flat-button class="c-btn-warn" (click)="decline()">Decline</button>
        <button [disabled]="form.invalid" mat-flat-button id="submit-btn" class="c-btn-green" (click)="sendToIubenda($event)">Accept</button>
      </div>
    </form>
  </div>
</div>
<ng-template #invalidConsentForm>
  <div class="notification-text">
    <h1>Oops, this consent form doesn't accessible</h1>
  </div>
</ng-template>
