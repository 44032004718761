import { Component, OnInit, DestroyRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';
import { AuthService } from '@core/services/auth/auth.service';
import { OrganizationDto } from '@core/services/organization/organization-dto';
import { SessionService } from '@core/services/session.service';
import { ILoginModel } from './force-update-device/force-update-device.component';
import { GoogleTagManagerHelperService } from "@shared/services/google-tag-manager-helper.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

const globalThis = window;
export interface IRole {
  id: number;
  name: string;
  is_agent_role: boolean;
  is_owner: boolean;
}
export interface IErrorFlag {
  is_payment_method: boolean;
  is_payment_method_valid: boolean;
  is_have_failed_invoice: boolean;
  error_code: string;
  error_message: string;
}
export interface IActivePaymentInfo {
  list_payment_method_type: string[];
  active_payment_method_id: string;
  active_payment_method_type: string;
}
export interface IAuth {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  role: IRole;
  organization: OrganizationDto;
  is_active: boolean;
  photo: string;
  phone: string;
  enable_logi: boolean;
  is_sse_validation_active: boolean;
  error_flag: IErrorFlag;
  active_payment_method_info: IActivePaymentInfo;
  is_admin: boolean;
  intercom_user_hash: string;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm!: UntypedFormGroup;
  public auth!: IAuth;
  public model: ILoginModel = { name: '', username: '', uuid: '', password: '' };
  public isSessionExpired: boolean = false;
  public isLoggedIn: boolean = false;
  public subscription: Subscription;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private sessionService: SessionService,
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private googleTagManagerHelperService: GoogleTagManagerHelperService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this.subscription = this.activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((): void => {
      if (localStorage.getItem('isLoggedIn') === 'true' || this.isSessionExpired) {
        this.isLoggedIn = true;
      } else if (localStorage.getItem('isLoggedIn') === 'false' || this.isSessionExpired) {
        this.isLoggedIn = false;
      }

      this.isSessionExpired = this.activatedRoute.snapshot.queryParams.expired_session;
      this.initForm();
    });
  }

  public initForm(): void {
    if (!this.isSessionExpired) {
      this.skipLoginIfAuthenticated();
    }
    const ControlsConfig = {
      username: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
    };
    this.loginForm = this.fb.group(ControlsConfig);
  }

  public skipLoginIfAuthenticated(): void {
    try {
      if (this.isLoggedIn) {
        this.router.navigate(['/home/dashboard']);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public login(): void {
    const validationErrorMessage: string = 'Please input email and password.';
    const alreadyLoggedInMessage: string = 'User already logged in another device';
    const invalidCredentialsMessage: string = 'Invalid user credentials.';

    if (!this.loginForm.valid) {
      this.snackBar.open(validationErrorMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return;
    }

    globalThis.FORCE_UPDATE_CONSENT = {};

    this.authService.login(this.loginForm.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (res: IAuth): void => {
          res.photo = "";
          this.auth = res;
          this.sessionService.setCookie('auth', JSON.stringify(res));
          this.authService.setCookiesForGTM(this.auth);
          this.authService.getRoles(this.auth.role.id, this.isSessionExpired);
          this.apiService.resetCountCallExpiredSession();
          this.sessionService.activateLoginStatus();
          this.getSession();
          this.setIdeTime();
          this.googleTagManagerHelperService.setDataLayer(res);
        },
        (error): void => {
          this.sessionService.deleteAllCookies();
          if (error && error === 'Error: Force update device') {
            globalThis.FORCE_UPDATE_DEVICE = {
              username: this.loginForm.get('username')?.value,
              password: this.loginForm.get('password')?.value,
            };
            globalThis.FORCE_UPDATE_CONSENT.username = this.loginForm.get('username')?.value;
            globalThis.FORCE_UPDATE_CONSENT.password = this.loginForm.get('password')?.value;
            if (this.isSessionExpired) {
              this.router.navigate(['/force-update-device'], {
                queryParams: {
                  expired_session: true,
                }
              });
            } else {
              this.router.navigateByUrl('/force-update-device');
            }
            return;
          }
          if (error && error === 'Error: Force update consent') {
            globalThis.FORCE_UPDATE_CONSENT.username = this.loginForm.get('username')?.value;
            globalThis.FORCE_UPDATE_CONSENT.password = this.loginForm.get('password')?.value;
            if (this.isSessionExpired) {
              this.router.navigate(['/force-update-consent'], {
                queryParams: {
                  expired_session: true,
                }
              });
            } else {
              this.router.navigateByUrl('/force-update-consent');
            }
            return;
          }
          if (error && error === 'Error: User is already logged in another device') {
            this.snackBar.open(alreadyLoggedInMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);

            return;
          }
          this.snackBar.open(invalidCredentialsMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        }
      );
  }

  public getSession(): void {
    this.apiService
      .get(AppConstants.API.SETTINGS.GET_CONFIGURATION, true, false)
      .pipe(
        catchError((error) => {
          throw error;
        })
      )
      .subscribe((res: any) => {
        const ideTime = res?.session_idle_time || 3600;
        localStorage.setItem(
          AppConstants.LOCAL_STORAGE_KEYS.IDE_TIME_UNIT,
          ideTime
        );
      });
  }

  public setIdeTime(): void {
    const ideTimeUnit: string | null = localStorage.getItem(
      AppConstants.LOCAL_STORAGE_KEYS.IDE_TIME_UNIT
    );

    if (ideTimeUnit) {
      const ideTime = moment().add(Number(ideTimeUnit) - 60, 'seconds');
      localStorage.setItem(
        AppConstants.LOCAL_STORAGE_KEYS.IDE_TIME,
        ideTime.format(AppConstants.DATE_FORMAT.DATE_TIME_MOMENT)
      );
    }
  }
}
