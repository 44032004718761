import { inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";

@Injectable()
export class MatIconRegistryService {
  private matIconRegistry: MatIconRegistry = inject(MatIconRegistry);
  private domSanitizer: DomSanitizer = inject(DomSanitizer);

  public registerCustomMatIcon(label: string, url: string): void {
    this.matIconRegistry.addSvgIcon(label, this.sanitizeSvgIconUrl(url));
  }

  private sanitizeSvgIconUrl(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
