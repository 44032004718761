<!-- ORGANIZATION & USER -->
<div class="side-org-user-container" *ngIf="isLoggedIn">
  <div class="">
    <img id="current_login_logo" src="" alt="organization logo" />
  </div>
  <div class="side-org-user-name-container">
    <span
      style="cursor: pointer"
      class="side-user-name"
      mat-button
      [routerLink]="['/home/dashboard']"
      routerLinkActive="active"
    >{{ auth.first_name }} {{ auth.last_name }}</span>
    <span class="side-org-name">{{ auth.organization.name }}</span>
  </div>
</div>

<mat-accordion class="left-menu" displayMode="flat" *ngIf="isLoggedIn">
  <!-- Home -->
  <mat-expansion-panel
    disabled
    routerLink="dashboard"
    routerLinkActive="active">
    <mat-expansion-panel-header data-component="home_menu">
      <mat-icon>home</mat-icon>
      <div mat-line>Home</div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <!-- End Home -->

  <!-- Athlete Performance Center -->
  <mat-expansion-panel
    *ngIf="
      rights.athletes_list &&
      (rights.metabolic_profiling_body_composition_list ||
        rights.metabolic_profiling_capacities_list ||
        rights.metabolic_profiling_metabolic_fingerprint_list ||
        rights.metabolic_profiling_performance_development_list ||
        rights.metabolic_profiling_metabolic_profile_list ||
        rights.metabolic_profiling_test_data_list ||
        rights.metabolic_profiling_trainings_zones_list ||
        rights.metabolic_profiling_trainings_zones_list ||
        rights.metabolic_profiling_single_athlete_list ||
        rights.metabolic_profiling_multi_athlete)
    "
    routerLinkActive="active">
    <mat-expansion-panel-header data-component="athlete-performance-center_menu">
      <mat-icon mat-list-icon>query_stats</mat-icon>
      <div mat-line>Athlete Performance Center</div>
    </mat-expansion-panel-header>
    <a
      *ngIf="
        rights.metabolic_profiling_body_composition_list ||
        rights.metabolic_profiling_capacities_list ||
        rights.metabolic_profiling_metabolic_fingerprint_list ||
        rights.metabolic_profiling_performance_development_list
      "
      mat-button
      [routerLink]="['/home/apc/setcard', currentTestId]"
      routerLinkActive="active"
      data-component="athlete-setcard_sub-menu"
    >
      <mat-icon>assignment_ind</mat-icon>
      Athlete Setcard
    </a>
    <a
      *ngIf="rights.metabolic_profiling_metabolic_profile_list"
      mat-button
      [routerLink]="['/home/apc/load-characteristics', currentTestId]"
      routerLinkActive="active"
      data-component="load-characteristics_sub-menu"
    >
      <span class="extra-inscyd-icon-moon-single"></span>
      Load characteristics
    </a>
    <a
      *ngIf="rights.metabolic_profiling_test_data_list && isShowTestMenu"
      mat-button
      [routerLink]="['/home/apc/test-data', currentTestId]"
      routerLinkActive="active"
      data-component="test-data_sub-menu"
    >
      <span class="iatac-icon-test-data"></span>
      Test Data
    </a>
    <a
      *ngIf="
        rights.metabolic_profiling_speed_relationships &&
        isShowSpeedRelationshipMenu
      "
      mat-button
      [routerLink]="['/home/apc/speed-relationships', currentTestId]"
      routerLinkActive="active"
      data-component="speed-relationships_sub-menu"
    >
      <mat-icon>insights</mat-icon>
      Speed relationships
    </a>
    <a
      mat-button
      *ngIf="rights.metabolic_profiling_trainings_zones_list"
      [routerLink]="['/home/apc/training-zones', currentTestId]"
      routerLinkActive="active"
      data-component="training-zones_sub-menu"
    >
      <span class="iatac-icon-training-zone"></span>
      Training Zones
    </a>
    <a
      *ngIf="isShowEconomy && rights.metabolic_profiling_economy"
      mat-button
      [routerLink]="['/home/apc/economy', currentTestId]"
      routerLinkActive="active"
      data-component="economy_sub-menu"
    >
      <span class="iatac-icon-economy"></span>
      Economy
    </a>
    <a
      *appHasPermission="['metabolic_profiling_time_to_depletion']"
      mat-button
      [routerLink]="['/home/apc/time-to-depletion', currentTestId]"
      routerLinkActive="active"
      data-component="time-to-depletion_sub-menu"
    >
      <span class="iatac-icon-time"></span>
      Time to depletion
    </a>
    <a
      *appHasPermission="['metabolic_profiling_recovery_matrix']"
      mat-button
      [routerLink]="['/home/apc/recovery-matrix', currentTestId]"
      routerLinkActive="active"
      data-component="recovery-matrix_sub-menu"
    >
      <span class="iatac-icon-recover-matrix"></span>
      Recovery Matrix
    </a>
    <a
      *appHasPermission="['performance_projection_page']"
      mat-button
      [routerLink]="['/home/apc/performance-projection', currentTestId]"
      routerLinkActive="active"
      data-component="performance-projection_sub-menu"
    >
      <span class="iatac-icon-recover-matrix"></span>
      Performance Projection
    </a>
  </mat-expansion-panel>
  <!-- End Athlete Performance Center -->

  <!-- Manage -->
  <mat-expansion-panel routerLinkActive="active">
    <mat-expansion-panel-header data-component="manage_menu">
      <span class="iatac-icon-manage"></span>
      <div mat-line>Manage</div>
    </mat-expansion-panel-header>
    <a
      *ngIf="rights.admin_users_list"
      routerLink="manage/users"
      mat-button
      routerLinkActive="active"
      data-component="users_sub-menu"
    ><span class="iatac-icon-user-group-man-man-filled"></span> Users</a>
    <a routerLink="manage/athlete" routerLinkActive="active" data-component="athletes_sub-menu" mat-button>
      <span
        class="extra-inscyd-icon-moon-athletes icon-moon-primary"
        routerLinkActive="active"
      >
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span>
      </span>
      Athletes</a
    >
    <a
      *ngIf="rights.files_list"
      routerLink="manage/files"
      routerLinkActive="active"
      data-component="files_sub-menu"
      mat-button
    >
      <mat-icon>insert_drive_file</mat-icon>
      Files
    </a>
    <a
      *ngIf="rights.test_list"
      routerLink="manage/tests"
      mat-button
      routerLinkActive="active"
      data-component="tests_sub-menu"
    >
      <span
        class="extra-inscyd-icon-moon-tests big-icon icon-moon-primary"
        routerLinkActive="active"
      >
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span>
        <span class="path5"></span>
        <span class="path6"></span>
        <span class="path7"></span>
        <span class="path8"></span>
        <span class="path9"></span>
        <span class="path10"></span>
        <span class="path11"></span>
        <span class="path12"></span>
      </span>
      Tests</a
    >
  </mat-expansion-panel>
  <!-- End Manage -->

  <!-- Team Competition -->
  <mat-expansion-panel
    *ngIf="
      rights.teams_n_competitions_list &&
      (rights.teams_list ||
        rights.competitions_list ||
        rights.participations_list)
    "
    routerLinkActive="active">
    <mat-expansion-panel-header data-component="team-competition_menu">
      <span class="iatac-icon-teams-competitions"></span>
      <div mat-line>Teams & Competitions</div>
    </mat-expansion-panel-header>
    <a
      *ngIf="rights.teams_list"
      routerLink="tac/teams"
      routerLinkActive="active"
      data-component="teams_sub-menu"
      mat-button
    ><span class="iatac-icon-teams"></span> Teams</a>
    <a
      *ngIf="rights.competitions_list"
      routerLink="tac/competitions"
      routerLinkActive="active"
      data-component="competitions_sub-menu"
      mat-button
    ><span class="iatac-icon-competition"></span> Competitions</a>
    <a
      *ngIf="rights.participations_list"
      routerLink="tac/participation"
      routerLinkActive="active"
      data-component="participation_sub-menu"
      mat-button
    ><span class="iatac-icon-participation"></span> Participation</a>
  </mat-expansion-panel>
  <!-- End Team Competition -->

  <!-- Load Analysis -->
  <mat-expansion-panel disabled *ngIf="rights.loads_list">
    <mat-expansion-panel-header data-component="load-analysis_menu">
      <a
        class="external-link"
        routerLink="load-analysis"
        routerLinkActive="active"
      >
        <span class="iatac-icon-load-analysis"></span>
        <div mat-line>Load Analysis</div>
      </a>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <!-- End Load Analysis -->

  <!-- Dynamic Simulation -->
  <mat-expansion-panel
    *ngIf="
      rights.dynamic_simulation_list &&
      (rights.dynamic_simulation_event_list ||
        rights.dynamic_simulation_result_list)
    "
    routerLinkActive="active">
    <mat-expansion-panel-header data-component="dynamic-simulation_menu">
      <mat-icon mat-list-icon>trending_up</mat-icon>
      <div mat-line>Dynamic Simulation</div>
    </mat-expansion-panel-header>
    <a
      *ngIf="rights.dynamic_simulation_event_list"
      [href]="v1Link + '#!/events'"
      routerLink="dynamic-simulation/manage-event"
      routerLinkActive="active"
      data-component="manage-event_sub-menu"
      mat-button>Manage Event</a>
    <a
      *ngIf="rights.dynamic_simulation_result_list"
      [href]="v1Link + '#!/results'"
      routerLink="dynamic-simulation/manage-result"
      routerLinkActive="active"
      data-component="manage-result_sub-menu"
      mat-button>Manage Result</a>
  </mat-expansion-panel>
  <!-- End Dynamic Simulation -->

  <!-- Courses -->
  <mat-expansion-panel
    *ngIf="rights.college_list"
    routerLinkActive="active">
    <mat-expansion-panel-header data-component="courses_menu">
      <mat-icon>school</mat-icon>
      <div mat-line>College</div>
    </mat-expansion-panel-header>
    <a routerLink="college/catalog" mat-button routerLinkActive="active" data-component="catalogue_sub-menu">Course Library</a>
    <a routerLink="college/dashboard" mat-button routerLinkActive="active" data-component="my-courses_sub-menu">My Progress</a>
    <a routerLink="college/my-courses" mat-button routerLinkActive="active" data-component="my-courses_sub-menu">My Courses</a>
    <!-- <a
      routerLink="courses/course"
      mat-button
      routerLinkActive="active">Course 1
    </a> -->
  </mat-expansion-panel>

  <!-- Administration -->
  <mat-expansion-panel *ngIf="rights.admin_list" routerLinkActive="active">
    <mat-expansion-panel-header data-component="administration_menu">
      <mat-icon mat-list-icon>settings</mat-icon>
      <div mat-line>Administration</div>
    </mat-expansion-panel-header>

    <a *ngIf="auth.role.is_owner || rights.admin_rights_list"
       routerLink="administration/rights-management"
       routerLinkActive="active"
       data-component="rights-management_sub-menu"
       mat-button>Rights Management
    </a>

    <a *ngIf="auth.role.is_owner || rights.admin_devices_list"
       routerLink="administration/device-management"
       routerLinkActive="active"
       data-component="devices_sub-menu"
       mat-button>
      Devices
    </a>

    <a *ngIf="rights.admin_tags_list"
       routerLink="administration/tags"
       routerLinkActive="active"
       data-component="edit-tags_sub-menu"
       mat-button>
      Edit Tags
    </a>

    <a *ngIf="auth.role.is_owner || rights.can_manage_sport_settings"
       routerLink="administration/sports"
       routerLinkActive="active"
       data-component="manage-sports_sub-menu"
       mat-button>Manage Sports</a>

    <!-- <a
      *ngIf="auth.role.is_owner || auth.role.is_agent_role"
      [href]="v1Link + '#!/system-settings'"
      mat-button
      >System Settings
    </a> -->
    <a *ngIf="auth.role.is_owner || auth.role.is_agent_role"
       routerLink="administration/system-settings"
       routerLinkActive="active"
       data-component="system-settings_sub-menu"
       mat-button>System Settings
    </a>
    <!-- <a
      *ngIf="auth.role.is_owner || auth.role.is_agent_role"
      [href]="v1Link + '#!/regions-pricing'"
      mat-button
      >Region Settings
    </a> -->
    <a *ngIf="auth.role.is_owner || auth.role.is_agent_role"
       routerLink="administration/region-pricing"
       routerLinkActive="active"
       data-component="region-settings_sub-menu"
       mat-button>Region Settings
    </a>
    <a *ngIf="auth.role.is_owner || auth.role.is_agent_role"
       routerLink="administration/account-types"
       routerLinkActive="active"
       data-component="account-types_sub-menu"
       mat-button>Account Types
    </a>
    <a *ngIf="auth.role.is_owner || auth.role.is_agent_role"
       routerLink="administration/products"
       routerLinkActive="active"
       data-component="products_sub-menu"
       mat-button>Products
    </a>
    <a *ngIf="auth.role.is_owner || auth.role.is_agent_role"
       routerLink="administration/pricing-plans"
       routerLinkActive="active"
       data-component="pricing-plans_sub-menu"
       mat-button>Pricing & Plans
    </a>
    <a *ngIf="auth.role.is_owner || auth.role.is_agent_role"
       routerLink="administration/courses"
       routerLinkActive="active"
       data-component="courses_sub-menu"
       mat-button>Courses
    </a>
    <a *ngIf="auth.role.is_owner || rights.tracking_api_access"
       routerLink="administration/api-tracking"
       routerLinkActive="active"
       data-component="api-tracking_sub-menu"
       mat-button>API Tracking
    </a>
    <a *ngIf="auth.role.is_owner || rights.admin_view_all_history_log"
       routerLink="administration/history-logs"
       routerLinkActive="active"
       data-component="history-log_sub-menu"
       mat-button>History Log
    </a>
    <a *ngIf="auth.role.is_owner"
       routerLink="administration/information-popup"
       routerLinkActive="active"
       data-component="information-popup_sub-menu"
       mat-button>Information Popup
    </a>
    <a *ngIf="(auth.role.is_owner || auth.role.is_agent_role) && rights.admin_org_list"
       routerLink="administration/manage-organizations"
       routerLinkActive="active"
       data-component="manage-organizations_sub-menu"
       mat-button>Manage Organizations
    </a>

    <a *ngIf="auth.role.is_owner || rights.training_zone_builder"
       routerLink="administration/training-zone-template"
       routerLinkActive="active"
       data-component="training-zone_sub-menu"
       mat-button>Training zone
    </a>

    <a *ngIf="
        !auth.role.is_owner &&
        !auth.role.is_agent_role &&
        rights.admin_org_settings_list"
       routerLink="administration/organization-settings"
       routerLinkActive="active"
       data-component="organization-common-settings_sub-menu"
       mat-button>Organization Common Settings
    </a>

    <a *ngIf="auth.role.is_owner"
       routerLink="plan-subscription/invoice-admin"
       mat-button
       routerLinkActive="active"
       data-component="manage-invoices_sub-menu"
    >
      Manage Invoices
    </a>

    <a *ngIf="rights.report_template_create"
       routerLink="administration/report-template"
       data-component="report-templates_sub-menu"
       mat-button
       routerLinkActive="active">Report Templates
    </a>
  </mat-expansion-panel>

  <!-- Plan & Subscription -->
  <mat-expansion-panel
    *ngIf="
      rights.subscription_view_plan ||
          rights.subscription_view_product ||
          rights.subscription_view_invoice_page ||
          rights.subscription_view_subscription_page ||
          rights.subscription_view_payment_method_page
    "
    routerLinkActive="active">
    <mat-expansion-panel-header data-component="my-account_menu">
      <mat-icon mat-list-icon>manage_accounts</mat-icon>
      <div mat-line>My Account</div>
    </mat-expansion-panel-header>
    <a
      *ngIf="rights.subscription_view_plan"
      routerLink="plan-subscription/plan-purchase"
      mat-button
      routerLinkActive="active"
      data-component="plan-purchase_sub-menu"
    >Plan Purchase</a>
    <a
      *ngIf="rights.subscription_view_product"
      routerLink="plan-subscription/product-purchase"
      mat-button
      routerLinkActive="active"
      data-component="product-purchase_sub-item"
    >Product Purchase</a>
    <a
      *ngIf="rights.subscription_view_subscription_page"
      routerLink="plan-subscription/my-subscription"
      mat-button
      routerLinkActive="active"
      data-component="my-subscription_sub-menu"
      >My Subscription</a
    >
    <a
      *ngIf="rights.subscription_view_payment_method_page"
      routerLink="plan-subscription/my-payment-method"
      mat-button
      routerLinkActive="active"
      data-component="my-payment-method_sub-menu"
    >My Payment Method</a>
    <a
      *ngIf="rights.subscription_view_invoice_page"
      routerLink="plan-subscription/invoice"
      mat-button
      routerLinkActive="active"
      data-component="invoices_sub-menu"
    >Invoices</a>
  </mat-expansion-panel>

  <!-- Feature Store -->
<!--  <mat-expansion-panel disabled *ngIf="rights.loads_list">-->
<!--    <mat-expansion-panel-header data-component="feature-store_menu">-->
<!--      <a class="external-link" routerLink="feature-store/catalog" routerLinkActive="active">-->
<!--        <span class="iatac-icon-load-analysis"></span>-->
<!--        <div mat-line>Feature Store</div>-->
<!--      </a>-->
<!--    </mat-expansion-panel-header>-->
<!--  </mat-expansion-panel>-->

<!--   My INSCYD-->
<!--    <mat-expansion-panel disabled *ngIf="true">-->
<!--      <mat-expansion-panel-header data-component="my-inscyd_menu">-->
<!--        <a class="external-link" routerLink="my-inscyd" routerLinkActive="active">-->
<!--          <span class="iatac-icon-load-analysis"></span>-->
<!--          <div mat-line>MY INSCYD</div>-->
<!--        </a>-->
<!--      </mat-expansion-panel-header>-->
<!--    </mat-expansion-panel>-->

<!--   My Profile-->
<!--    <mat-expansion-panel disabled *ngIf="true">-->
<!--      <mat-expansion-panel-header data-component="my-profile_menu">-->
<!--        <a class="external-link" routerLink="my-profile" routerLinkActive="active">-->
<!--          <span class="iatac-icon-load-analysis"></span>-->
<!--          <div mat-line>My Profile</div>-->
<!--        </a>-->
<!--      </mat-expansion-panel-header>-->
<!--    </mat-expansion-panel>-->
</mat-accordion>
