import { AppBaseComponent } from '@shared/components/app-component-base';
import { Component, ViewChild } from '@angular/core';
import { Sort, MatSort, MatSortable } from '@angular/material/sort';
import { Deserializable } from '@shared/models/deserializable';

export class PagedResultDto extends Deserializable {
  public items: any[] = [];
  public totalCount: number = 0;
}

export class EntityDto {
  public id: number = 0;
}

export class PagedRequestDto {
  public skipCount: number = 0;
  public maxResultCount: number = 0;
  public keyword: string = '';
  public pageIndex: number = 0;
}

export class PagedAndSortedRequestDto extends PagedRequestDto {
  public sorting: string | undefined;
  public sortByDesc: boolean | undefined;
}

@Component({
  template: '',
})
export abstract class PagedListingBaseComponent<TEntityDto> extends AppBaseComponent {
  @ViewChild(MatSort, { static: true }) matSort: MatSort | undefined;
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public pageSize: number = 10;
  public pageNumber: number = 1;
  public totalItems: number = 0;
  public pageIndex: number = 0;
  public defaultSort = {
    sorting: '',
    start: '',
  };

  public initialDefaultSort(): void {
    if (!this.matSort) {
      return;
    }
    this.matSort.sort({
      id: this.defaultSort.sorting,
      start: this.defaultSort.start,
    } as MatSortable);
  }

  public onInitPage(): void {
    this.onInitSort();
  }

  public onInitSort(): void {
    if (!this.defaultSort.sorting || !this.defaultSort.start) {
      this.refresh();
      return;
    }
    this.initialDefaultSort();
  }

  public search(keyword: string): void {
    this.request.keyword = keyword;
    this.getDataPage(1);
  }

  public refresh(): void {
    this.getDataPage(this.pageNumber);
  }

  public sortData(sort: Sort): void {
    if (sort) {
      this.request.sorting = sort.direction ? sort.active : undefined;
      this.request.sortByDesc = sort.direction
        ? sort.direction === 'desc'
        : undefined;
    }
    this.getDataPage(this.pageNumber);
  }

  public getDataPage(page: number): void {
    const req: PagedRequestDto = this.getPagedRequestDto();
    req.maxResultCount = this.pageSize;
    req.skipCount = (page - 1) * this.pageSize;
    this.pageNumber = page;
    this.pageIndex = page - 1;
    this.isDataLoading = true;
    this.list(req, page, (): void => {
      this.customRendererService.hide('.loading_layout');
      this.isDataLoading = false;
    });
  }

  protected abstract getPagedRequestDto(): PagedRequestDto;
  protected abstract list(
    request: PagedRequestDto,
    pageNumber: number,
    finishedCallback: Function
  ): void;
}
