<div id="loading" class="loading_layout" style="display: none">
  <div
    id="loading_wrapper"
    class="loading_wrapper"
    title="Click to hide loading"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="full-page-container">
  <div class="congratulation-box">
    <div class="text-box">
      <mat-icon>done_outline</mat-icon><span class="margin-left-10">Course has been completed</span>
    </div>
  </div>
</div>
