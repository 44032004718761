import { Component, DestroyRef } from '@angular/core';
import { AppBaseComponent } from '@shared/components/app-component-base';
import SwiperCore, { Pagination } from 'swiper';
import { AppConstants } from '@core/constants';
import { ApiService } from "@core/services/api.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Router } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends AppBaseComponent {
  public v1URL: string = AppConstants.V1_URL;
  public selectedTestID: any = null;
  public courses: any = [];

  constructor(private apiService: ApiService,
              private snackBar: MatSnackBar,
              private router: Router,
              private destroyRef: DestroyRef,
              ) {
    super();
  }

  public onInitPage(): void {
    const HARD_CODED_COURSE_ID_LIST: number[] = [191, 150, 183, 179, 156, 184];
    this.selectedTestID = localStorage.getItem('testID');
    if (this.rights.college_list) {
      this.apiService.get(AppConstants.API.COURSE.USER_CATALOG)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((response: any): void => {
          this.courses = response.filter((item: any) => !item.is_purchased && HARD_CODED_COURSE_ID_LIST.includes(item.id)).slice(0, 3);
          this.courses.push({ isFakeItem: true });
        }, (err: any): void => {
          this.customRendererService.hide('.loading_layout');
          this.snackBar.open(err, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        });
    }
  }

  public goToCatalogue(): void {
    this.router.navigate(['/home/college/catalog']);
  }
}
