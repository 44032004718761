import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { SharedModule } from '@shared/shared.module';
import { HomeComponent } from '@modules/home/home.component';
import { E404Component } from '@modules/errors/e404/e404.component';
import { TestService } from '@core/services/test/test.service';
import { TrainingZoneService } from '@core/services/training-zone/training-zone.service';
import { SportService } from '@core/services/sports/sport.service';
import { LoginComponent } from '@modules/login/login.component';
import { NewAppComponent } from '@modules/new-app/new-app.component';
import { DateFormatPipe } from '@shared/pipes/date-format.pipe';
import { MyDevicesComponent } from '@modules/my-devices/my-devices.component';
import { SingleAthleteMetabolicProfilingComponent } from '@modules/tests/single-athlete-metabolic-profiling/single-athlete-metabolic-profiling.component';
import { ForgotPasswordComponent } from '@modules/login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@modules/login/reset-password/reset-password.component';
import { PpdTestComponent } from '@modules/tests/ppd-test/ppd-test.component';
import { TabsComponent } from '@modules/tests/tabs/tabs.component';
import { MeanMaxPowerDialogComponent } from '@modules/tests/dialogs/mean-max-power-dialog/mean-max-power-dialog.component';
import { SingleEffortDialogComponent } from '@modules/tests/dialogs/single-effort-dialog/single-effort-dialog.component';
import { RampTestDialogComponent } from "@modules/tests/dialogs/ramp-test-dialog/ramp-test-dialog.component";
import { SprintDialogComponent } from '@modules/tests/dialogs/sprint-dialog/sprint-dialog.component';
import { MaxEffortDialogComponent } from '@modules/tests/dialogs/max-effort-dialog/max-effort-dialog.component';
import { VlaMaxDialogComponent } from '@modules/tests/dialogs/vla-max-dialog/vla-max-dialog.component';
import { Vo2MaxDialogComponent } from '@modules/tests/dialogs/vo2-max-dialog/vo2-max-dialog.component';
import { AnaerobicThresholdDialogComponent } from '@modules/tests/dialogs/anaerobic-threshold-dialog/anaerobic-threshold-dialog.component';
import { SingleComponent } from '@modules/tests/single/single.component';
import { ForceUpdateDeviceComponent } from '@modules/login/force-update-device/force-update-device.component';
import { LoadAnalysisComponent } from '@modules/load-analysis/load-analysis/load-analysis.component';
import { CollegeComponent } from '@modules/courses/college.component';
import { SwiperModule } from 'swiper/angular';
import { LoginRegistrationComponent } from '@modules/login/login-registration/login-registration.component';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { AdditionalInfoComponent } from '@modules/login/additional-info/additional-info.component';
import { CreditCardDirectivesModule } from "angular-cc-library";
import { MatRippleModule } from "@angular/material/core";
import { ReportPreviewComponent } from '@modules/report-preview/report-preview/report-preview.component';
import { AdministrationModule } from "@modules/administration/administration.module";
import { EditTextPreviewComponent } from "@shared/components/edit-text-preview/edit-text-preview.component";
import { PrivacyPolicyComponent } from "@shared/components/privacy-policy/privacy-policy.component";
import { TermsComponent } from "@shared/components/terms/terms.component";
import { ForceUpdateConsentComponent } from "@modules/login/force-update-consent/force-update-consent.component";
import { ConfirmSharedDataComponent } from "@shared/components/confirm-shared-data/confirm-shared-data.component";
import { CourseCompleteComponent } from "@shared/components/course-complete/course-complete.component";
import { TextEditorDialogComponent } from "@shared/dialogs/text-editor-dialog/text-editor-dialog.component";
import { GoogleTagManagerHelperService } from "@shared/services/google-tag-manager-helper.service";
import { TableService } from '@shared/services/table.service';
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { AddFitFileDialogComponent } from '@modules/tests/dialogs/add-fit-file-dialog/add-fit-file-dialog.component';
import { FitFileParserService } from '@shared/services/fit-file-parser.service';
import { MetabolicProfileComponent } from '@modules/humango/metabolic-profile/metabolic-profile.component';
import { D3MultipleXAxisChartService } from "@shared/services/d3/multiple-x-axis-chart/d3-multiple-x-axis-chart.service";
import { PaymentLinkPlanComponent } from '@modules/payment-link/plan/plan.component';
import { AddressFormComponent } from './modules/login/additional-info/address-form/address-form.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        E404Component,
        LoginComponent,
        NewAppComponent,
        MyDevicesComponent,
        PpdTestComponent,
        TabsComponent,
        MeanMaxPowerDialogComponent,
        SingleEffortDialogComponent,
        RampTestDialogComponent,
        SprintDialogComponent,
        MaxEffortDialogComponent,
        VlaMaxDialogComponent,
        Vo2MaxDialogComponent,
        AnaerobicThresholdDialogComponent,
        SingleComponent,
        SingleAthleteMetabolicProfilingComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        ForceUpdateDeviceComponent,
        LoadAnalysisComponent,
        CollegeComponent,
        LoginRegistrationComponent,
        AdditionalInfoComponent,
        ReportPreviewComponent,
        EditTextPreviewComponent,
        PrivacyPolicyComponent,
        TermsComponent,
        ForceUpdateConsentComponent,
        ConfirmSharedDataComponent,
        CourseCompleteComponent,
        TextEditorDialogComponent,
        AddFitFileDialogComponent,
        MetabolicProfileComponent,
        PaymentLinkPlanComponent,
        AddressFormComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        SwiperModule,
        HttpClientModule,
        SharedModule,
        NgxStripeModule.forRoot(environment.stripeKey),
        CreditCardDirectivesModule,
        MatRippleModule,
        AdministrationModule
    ],
    providers: [
        GoogleTagManagerHelperService,
        TrainingZoneService,
        SportService,
        TestService,
        TableService,
        DateFormatPipe,
        CustomRendererService,
        FitFileParserService,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                floatLabel: 'always',
            },
        },
        D3MultipleXAxisChartService,
    ],
    bootstrap: [AppComponent],
    exports: [
        ConfirmSharedDataComponent
    ]
})
export class AppModule {}
