import { Injectable } from '@angular/core';
import { IProfileItem } from "@shared/interfaces/profile-item.interface";

@Injectable()
export class MyProfileService {
  public getProfileItemList(): IProfileItem[] {
    return [
      {
        title: 'Account Info',
        description: 'Address (Billing & street), Logo, usage statistics',
        urlNavigateTo: '/home/my-profile/detail',
        iconUrl: 'assets/custom-icons/account-info.svg',
        iconLabel: 'account-info',
      },
      {
        title: 'Personal Info',
        description: 'Personal info of user: adress, email, PW',
        urlNavigateTo: '/home/my-profile/detail',
        iconUrl: 'assets/custom-icons/personal-info.svg',
        iconLabel: 'personal-info',
      },
      {
        title: 'Devices',
        description: 'Current manage device',
        urlNavigateTo: '/home/my-profile/detail',
        iconUrl: 'assets/custom-icons/devices.svg',
        iconLabel: 'devices',
      },
      {
        title: 'Tags',
        description: 'Current manage tags',
        urlNavigateTo: '/home/my-profile/detail',
        iconUrl: 'assets/custom-icons/tags.svg',
        iconLabel: 'tags',
      },
      {
        title: 'Integrations',
        description: 'Automatically create API keys, API tracking',
        urlNavigateTo: '/home/my-profile/detail',
        iconUrl: 'assets/custom-icons/integrations.svg',
        iconLabel: 'integrations',
      },
      {
        title: 'Security',
        description: 'Current Security settings',
        urlNavigateTo: '/home/my-profile/detail',
        iconUrl: 'assets/custom-icons/security.svg',
        iconLabel: 'security',
      },
      {
        title: 'Subscriptions',
        description: 'Current My subscriptions page, plus feature store purchases',
        urlNavigateTo: '/home/my-profile/detail',
        iconUrl: 'assets/custom-icons/subscriptions.svg',
        iconLabel: 'subscriptions',
      },
      {
        title: 'Billing',
        description: 'Current Invoices, plus receipts, plus change payment methods',
        urlNavigateTo: '/home/my-profile/detail',
        iconUrl: 'assets/custom-icons/billing.svg',
        iconLabel: 'billing',
      },
    ];
  }
}
