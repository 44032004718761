<div class="u-screen-height additional-info-page">
  <mat-card class="addinfo__card">
    <div class="addinfo__card__header">
      <mat-card-title>Account Setup</mat-card-title>
      <mat-card-subtitle>Finish setting up your account and choose some sample datasets to get you started</mat-card-subtitle>
    </div>
    <mat-card-content>

      <form class="addinfo__form" [formGroup]="additionalInfoForm" (ngSubmit)="submit()">
        <h3 class="text-header">Company details</h3>
        <div class="addinfo__form__group">
          <mat-form-field class="u-color-white addinfo__form__control" floatLabel="auto">
            <mat-label>Company name</mat-label>
            <input matInput formControlName="organizationName" placeholder="Company name" required />
          </mat-form-field>
          <div class="u-color-white addinfo__form__control">
            <div class="addinfo__fileinput__label">Company Logo</div>
            <div class="addinfo__fileinput__field">
              <input type="file" #filePicker (change)="onFilePicked($event)" accept="image/png, image/jpeg" />
            </div>
          </div>
        </div>
        <mat-form-field class="u-color-white addinfo__form__control" floatLabel="auto">
          <mat-label>VAT number</mat-label>
          <input matInput formControlName="vatNumber" placeholder="VAT Number" />
        </mat-form-field>

        <h3 class="text-header">Address details</h3>
        <app-address-form formControlName="address" [countries]="countryList" [horizontal]="true"></app-address-form>

        <h3 class="text-header">Invoice address</h3>
        <mat-checkbox formControlName="sameInvoiceAddress">Same as company address</mat-checkbox>
        <app-address-form *ngIf="showInvoiceAddressForm" formControlName="invoiceAddress" [countries]="countryList" [horizontal]="true"></app-address-form>

        <h3 class="text-header">Sample Datasets</h3>
        These will be loaded into the account to get you started with our tools immediately.
        <mat-form-field class="u-color-white addinfo__form__control" floatLabel="auto">
          <mat-label>Sample datasets</mat-label>
          <mat-select formControlName="datasets" placeholder="Sample datasets" multiple>
            <mat-option *ngFor="let dts of datasetList" [value]="dts.slug">{{dts.name}}</mat-option>
          </mat-select>
        </mat-form-field>

      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="submit()" matTooltip="Confirm" [disabled]="!additionalInfoForm.valid">Confirm</button>
      <button mat-button (click)="logout()" matTooltip="Logout">Logout</button>
    </mat-card-actions>
  </mat-card>
</div>
