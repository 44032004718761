<div class="full-page-container">
    <div class="form">
      <div class="form-group text-center">
        <h1>Name your device</h1>
        <h4>
            This is the first time you log in with this device, please name your device
        </h4>
      </div>
      <div class="field" [formGroup]="form">
        <mat-form-field appearance="outline" [style.width.px]="350">
          <input
            class="loginInput"
            type="text"
            matInput
            placeholder="Device Name"
            formControlName="name"
          />
        </mat-form-field>
      </div>
      <button class="c-btn-blue" (click)="updateDeviceName()">Update</button>
      <div *ngIf="devices && max_devices">
        <br>
        <div class="message">
          <label>Max devices exceeded. Please remove some devices to continue. Total devices allowed: {{max_devices}}</label>
        </div>
        <table class="deviceTable">
          <thead>
            <tr>
                <th>Device Name</th>
                <th>IP Address</th>
                <th>Action</th>
            </tr>
          </thead>
          <tr *ngFor="let device of devices.data">
            <td>{{device.name}}</td>
            <td>{{device.ip}}</td>
            <td><button (click)="deleteDevice(device.id)"><i class="fa fa-trash"></i> Delete</button></td>
          </tr>
        </table>
      </div>
      <a class="back-login-link" [routerLink]="['']">Back to Login</a>
    </div>
</div>
