import { Injectable, inject } from "@angular/core";
import { Router, CanActivateFn } from "@angular/router";
import { AppConstants } from "@core/constants";

@Injectable()
export class DevelopmentOnlyGuardService {
  constructor(private router: Router) {}

  public canActivate(): boolean {
    const isProduction: boolean = location.origin.includes(AppConstants.ENVIRONMENT_URL.PROD.slice(0, -1));
    if (!isProduction) {
      return true;
    } else {
      this.router.navigateByUrl('/home/dashboard');
      return false;
    }
  }
}

export const DevelopmentOnlyGuard: CanActivateFn = (): boolean => {
  return inject(DevelopmentOnlyGuardService).canActivate();
};
