import { Injectable } from '@angular/core';
import { AppConstants } from "@core/constants";

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private laptopView: number = 1300;
  private tabletView: number = 1100;

  public replaceCorrectPhotoURL(photoURL: string | undefined): string {
    if (!photoURL) return '';
    return photoURL.replace('media/uploads', 'dist');
  }

  public isShowTestDataMenu(data: any): boolean {
    return data?.test && data?.test?.is_show_test_data;
  }

  public isShowEconomyMenu(dataChart: any): boolean {
    const dataEconomy = dataChart?.data[6] || [];
    return (
      dataChart?.test && dataChart?.test?.is_show_economy &&
      dataEconomy?.economy_values?.length
    );
  }

  public isShowSpeedRelationshipMenu(data: any): boolean {
    return data?.test && data?.test?.is_show_speed_relationship;
  }

  public findArrayDiff<T, U extends keyof T>(array1: Array<T>, array2: Array<T>, value: U): T[] {
    // Find values that are in array1 but not in array2
    return array1.filter((array1Item) => !array2.some((array2item) => array1Item[value] === array2item[value]));
  }

  public isEmptyObject(value: object): boolean | string {
    if (value.constructor !== Object) {
      return 'The passed value is not an Object type';
    }

    return !Object.keys(value).length;
  }

  public makeCopy<T>(reference: T): T {
    return JSON.parse(JSON.stringify(reference));
  }

  public hasProperty(object: any, property: string): boolean {
    return object.hasOwnProperty(property);
  }

  public isSpeedBasedSport(simulationType: number): boolean {
    const INDEX_SPEED_SPORT: number = 0;

    return AppConstants.SPORT_SIMULATION_TYPES[INDEX_SPEED_SPORT].id === simulationType;
  }

  public isPowerBasedSport(simulationType: number): boolean {
    const INDEX_POWER_SPORT: number = 1;

    return AppConstants.SPORT_SIMULATION_TYPES[INDEX_POWER_SPORT].id === simulationType;
  }

  public getFatmaxATFromTest(gaugePlotArray: any): any {
    const resultArrayWithATAndFatMax: any = {};
    const searchStringArray = ['at', 'fatmax'];

    searchStringArray?.forEach((searchItem) => {
      gaugePlotArray?.forEach((plot: any) => {
        if (plot.type === searchItem) {
          if (searchItem === 'at') {
            resultArrayWithATAndFatMax[searchItem] = plot.circles[0].source.names[0].split('relative: ')[1];
          } else if (searchItem === 'fatmax') {
            resultArrayWithATAndFatMax[searchItem] = plot.circles[0].source.names[0].split('@ ')[1];
          }

        }
      })
    });

    return resultArrayWithATAndFatMax;
  }

  public isSpeedMS(sportId: number): boolean {
    const SPEED_SPORT_M_S_INDEX: number = 0;

    return AppConstants.REFERENCE_SYSTEMS_SPEED[SPEED_SPORT_M_S_INDEX].id === sportId;
  }

  public isSpeedKmH(sportId: number): boolean {
    const SPEED_SPORT_KM_H_INDEX: number = 1;

    return AppConstants.REFERENCE_SYSTEMS_SPEED[SPEED_SPORT_KM_H_INDEX].id === sportId;
  }

  public isSpeedMph(sportId: number): boolean {
    const SPEED_SPORT_MPH_INDEX: number = 2;

    return AppConstants.REFERENCE_SYSTEMS_SPEED[SPEED_SPORT_MPH_INDEX].id === sportId;
  }

  public isPaceMinSecKm(sportId: number): boolean {
    const PACE_SPORT_MIN_SEC_KM_INDEX: number = 3;

    return AppConstants.REFERENCE_SYSTEMS_SPEED[PACE_SPORT_MIN_SEC_KM_INDEX].id === sportId;
  }

  public isPaceMinSecMile(sportId: number): boolean {
    const PACE_SPORT_MIN_SEC_MILE_INDEX: number = 4;

    return AppConstants.REFERENCE_SYSTEMS_SPEED[PACE_SPORT_MIN_SEC_MILE_INDEX].id === sportId;
  }

  public isPaceMinSec100Meter(sportId: number): boolean {
    const PACE_SPORT_MIN_SEC_100_METER_INDEX: number = 5;

    return AppConstants.REFERENCE_SYSTEMS_SPEED[PACE_SPORT_MIN_SEC_100_METER_INDEX].id === sportId;
  }

  public isPaceMinSec500Meter(sportId: number): boolean {
    const PACE_SPORT_MIN_SEC_500_METER_INDEX: number = 6;

    return AppConstants.REFERENCE_SYSTEMS_SPEED[PACE_SPORT_MIN_SEC_500_METER_INDEX].id === sportId;
  }

  public isPowerWat(sportId: number): boolean {
    const POWER_SPORT_POWER_WATT_INDEX: number = 0;

    return AppConstants.REFERENCE_SYSTEMS_POWER[POWER_SPORT_POWER_WATT_INDEX].id === sportId;
  }

  public isPowerWatKg(sportId: number): boolean {
    const POWER_SPORT_POWER_WATT_KG_INDEX: number = 1;

    return AppConstants.REFERENCE_SYSTEMS_POWER[POWER_SPORT_POWER_WATT_KG_INDEX].id === sportId;
  }

  public getUniqueValueByPropertyName(list: any[], propertyName: string): string[] {
    let resultList: any = [];

    list.forEach((item: any): void => {
      if (resultList.indexOf(item[propertyName]) === -1) {
        resultList.push(item[propertyName]);
      }
    });

    return resultList;
  }

  public getSwiperConfig(windowWidth: number): any {
    if (windowWidth < this.laptopView && windowWidth > this.tabletView) {
      return this.generateSwiperConfig(2);
    } else if (windowWidth < this.tabletView) {
      return this.generateSwiperConfig(1);
    } else {
      return this.generateSwiperConfig(3);
    }
  }

  private generateSwiperConfig(slidesPerView: number): any {
    return {
      navigation: {},
      slidesPerView: slidesPerView,
      spaceBetween: 20,
      watchSlidesProgress: true,
      pagination: { clickable: true },
    };
  }

  public isArray(value: any): boolean {
    return Object.prototype.toString.call(value) === '[object Array]';
  }

  public isObject(ref: Object) : boolean {
    return Object.prototype.toString.call(ref) === '[object Object]';
  }
}
