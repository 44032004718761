import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder,UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { combineLatest, of } from 'rxjs';
import { ApiService } from '@core/services/api.service';
import { RightConstants } from '../constants/right-constants';
import * as _ from 'lodash-es';
import { IRightDetail } from '@core/services/rights-management/rights-management-dto';
import { RightsManagementService } from '@core/services/rights-management/rights-management.service';
import { finalize } from 'rxjs/operators';

export interface IRightList {
  name: string;
  options: Array<{ name: string; path: string }>;
  subGroup: Array<{
    name: string;
    options: Array<{ name: string; path: string }>;
  }>;
}

@Component({
  selector: 'app-rights-management-dialog',
  templateUrl: './rights-management-dialog.component.html',
  styleUrls: ['./rights-management-dialog.component.scss'],
})
export class RightsManagementDialogComponent extends AppBaseComponent {
  public formInfor: UntypedFormGroup = {} as UntypedFormGroup;
  public organizationOption: Array<{ value: number; label: string }> = [];
  public filteredOptions: any;
  public rightOptions: any;
  public rightList: Array<IRightList> = [];
  public isOwnerOrAgent: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RightsManagementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly fb: UntypedFormBuilder,
    private readonly apiService: ApiService,
    private readonly rightsManagementService: RightsManagementService
  ) {
    super();
  }

  public onInitPage(): void {
    this.isOwnerOrAgent = this.auth.role.is_agent_role || this.auth.role.is_owner;
    this.initForm();
    this.initOption();
    this.formInfor.controls.organization.valueChanges.subscribe((res): void => {
      const value = typeof res === 'string' ? res : res?.label;
      if (value) {
        this.filteredOptions = this.organizationOption.filter((item) =>
          item.label.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.filteredOptions = this.organizationOption;
      }
    });
  }

  private initForm(): void {
    this.formInfor = this.fb.group({
      name: new UntypedFormControl('', Validators.required),
      isAgentRole: new UntypedFormControl(false, Validators.required),
      organization: new UntypedFormControl(''),
      isActive: new UntypedFormControl(true),
    });
  }

  private initOption(): void {
    this.rightOptions = _.cloneDeepWith(RightConstants.DEFAULT_RIGHTS);
    this.rightList = _.cloneDeepWith(RightConstants.RIGHTS_LIST_DISPLAY);

    combineLatest([
      this.apiService.get(this.constant.API.ORGANIZATIONS.LIST),
      this.apiService.get(this.constant.API.SPORT.LIST, true, false),
      this.data.id
        ? this.rightsManagementService.getById(this.data.id, true, false)
        : of(null),
    ])
      .pipe(finalize(() => this.customRendererService.hide('.loading_layout')))
      .subscribe(([resOrganizations, resSports, resDetail]: any) => {
        if (resOrganizations?.results?.length) {
          this.organizationOption = resOrganizations.results.map(
            (item: any) => {
              return {
                value: item?.id,
                label: item?.name,
              };
            }
          );
          this.filteredOptions = this.organizationOption;
        }
        if (resSports?.results?.length) {
          const sportConstanst: IRightList | undefined = this.rightList.find(
            (item: IRightList): boolean => item.name === 'Sports'
          );
          const resSprotsMapping = resSports.results.map((item: any) => {
            const path: string = `sport_can_use_id_${item?.id}_${item.name
              .toLowerCase()
              .replace(/ /g, '_')}`;
            this.rightOptions[path] = true;
            return {
              name: `Can use ${item?.name}`,
              path: path,
            };
          });
          if (sportConstanst) {
            sportConstanst.subGroup[0].options.push(...resSprotsMapping);
          }
        }

        if (this.data.id) {
          this.initData(resDetail);
        }
      });
  }

  private initData(data: IRightDetail): void {
    const {
      is_active,
      is_agent_role,
      organization,
      name,
      right_management = '',
    } = data;
    this.formInfor.patchValue({
      name: this.data.isCopy ? name + ' copy' : name,
      isActive: is_active,
      isAgentRole: is_agent_role,
      organization: this.organizationOption.find(
        (item) => item.value === organization
      ),
    });
    const rightManagement = JSON.parse(right_management);
    const rightDisplay = Object.keys(this.rightOptions);
    rightDisplay.forEach((item: string): void => {
      this.rightOptions[item] = rightManagement[item] || false;
    });
  }

  public save(): void {
    if (this.isValidForm()) {
      this.handlePatchDataOrganization();
      const { name, isAgentRole, organization, isActive } =
        this.formInfor.value;
      const input: IRightDetail = {
        id: this.data.isCopy ? null : this.data.id || null,
        is_owner: this.data.id === 1,
        is_organization: this.data.id !== 1,
        name,
        is_active: isActive,
        right_management: JSON.stringify(this.rightOptions),
        organization,
        is_agent_role: isAgentRole,
      } as IRightDetail;

      if (this.isOwnerOrAgent) {
        input.organization = isAgentRole ? 1 : organization?.value || null;
      } else {
        input.organization = this.auth.organization.id;
        input.is_agent_role = false;
      }
      if (this.data.isCopy || !this.data.id) {
        this.rightsManagementService.create(input).subscribe((res: Object): void => {
          this.dialogRef.close(res);
        });
      } else {
        this.rightsManagementService
          .update(this.data.id, input)
          .subscribe((res: Object): void => {
            this.dialogRef.close(res);
          });
      }
    }
  }

  private handlePatchDataOrganization(): void {
    if (this.formInfor.value.isAgentRole) {
      this.formInfor.patchValue({
        organization: this.organizationOption[0],
      });
    }
  }

  private isValidForm(): boolean {
    let isValid;
    if (this.formInfor.valid) {
      isValid = true;
    } else {
      isValid = false;
      this.formInfor.markAllAsTouched();
    }

    return isValid;
  }

  public close(): void {
    this.dialogRef.close(false);
  }
}
