import { Component, DestroyRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppConstants } from '@core/constants';
import { catchError, finalize } from 'rxjs/operators';
import { SessionService } from '@core/services/session.service';
import { ApiService } from '@core/services/api.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { environment } from 'src/environments/environment';
import { Observable, Observer } from 'rxjs';
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

export class EventDto {
  public organization: any;
  public id: number | undefined;

  public init(_data?: any): void {
    if (_data) {
      this.organization = _data['organization'];
      this.id = _data['id'];
    }
  }
  static fromJS(data: any): EventDto {
    data = typeof data === 'object' ? data : {};
    let result: EventDto = new EventDto();
    result.init(data);
    return result;
  }
}

@Component({
  selector: 'app-create-event-dialog',
  templateUrl: './create-event-dialog.component.html',
})
export class CreateEventDialogComponent implements OnInit {
  public eventForm: UntypedFormGroup = this.fb.group({});
  public _appConstants = AppConstants;
  public allTagsLst: any;
  public allTags: any;
  public allSportsLst: any;
  public allSports: any;
  public fileToUpload: File | undefined;
  public planId: string | undefined;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateEventDialogComponent>,
    private sessionService: SessionService,
    private _apiService: ApiService,
    private snackBar: MatSnackBar,
    private customRendererService: CustomRendererService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.fetchSports();
    this.fetchTags();
    this.initEventForm();
  }
  public fetchTags(): void {
    this._apiService.get('tags/all/')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response: any): void => {
      this.allTagsLst = response.results.sort();
      this.allTags = this.allTagsLst?.slice();
    });
  }

  public fetchSports(): void {
    this._apiService.get('sports/')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response: any) => {
      this.allSportsLst = response.results.sort();
      this.allSports = this.allSportsLst?.slice();
    });
  }
  public initEventForm(): void {
    this.eventForm = this.fb.group({
      name: new UntypedFormControl('', Validators.required),
      date: new UntypedFormControl('', Validators.required),
      sport: new UntypedFormControl('', Validators.required),
      desc: new UntypedFormControl('', Validators.required),
      tag: new UntypedFormControl([]),
    });
  }
  public formatDateDOB(date: any): string {
    let d: Date = new Date(date),
      month: string = '' + (d.getMonth() + 1),
      day: string = '' + d.getDate(),
      year: number = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  public closeDialog(): void {
    this.dialogRef.close();
  }

  public saveAction(): void {
    let profileData: EventDto = EventDto.fromJS(
      JSON.parse(this.sessionService.getCookie('auth'))
    );
    if (this.eventForm.status !== 'INVALID' && !this.fileToUpload) {
      this.snackBar.open('Please full fill the form', 'OK');
      return;
    }
    if (!this.fileToUpload) {
      this.snackBar.open('Please import data', 'OK');
      return;
    }
    let temp: string = this.formatDateDOB(this.eventForm.get('date')?.value);
    this.eventForm.get('date')?.setValue(temp);
    this._apiService.get('invoices/plans/', false)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
      (planList: any): void => {
        const planWithEvent = planList.find((plan: any) => plan.total_test_event < plan.max_test_event);

        if (planWithEvent) {
          this.planId = planWithEvent.id;

          let body: any = {
            coach: profileData?.id,
            name: this.eventForm.get('name')?.value,
            description: this.eventForm.get('desc')?.value,
            event_date: this.eventForm.get('date')?.value,
            organization: profileData?.organization?.id,
            sport: this.eventForm.get('sport')?.value,
            excel_file: this.fileToUpload,
          };

          const formTags = this.eventForm.get('tag')?.value;
          if (formTags.length) {
            body.tags = formTags;
          }

          this.xhrCall(environment.api + `events/?plan=` + this.planId, body)
            .pipe(
              takeUntilDestroyed(this.destroyRef),
              catchError(this._apiService.handleError),
              finalize((): void => {
                this.customRendererService.hide('.loading_layout');
              })
            )
            .subscribe(
              (): void => {
                this.dialogRef.close({ status: true, notification: AppConstants.NOTIFICATIONS.SUCCESSFULLY_ADD_EVENT });
              },
              (): void => {
                this.dialogRef.close({ status: false, notification: AppConstants.NOTIFICATIONS.UNSUCCESSFULLY_ADD_EVENT });
              }
            );
        } else {
          this.customRendererService.hide('.loading_layout');
          this.snackBar.open('There are not plans with event or you exceed count of event items', 'OK', AppConstants.TOAST_CONFIG.ERROR);
        }

      },
      (): void => {
        this.dialogRef.close({ status: false, notification: AppConstants.NOTIFICATIONS.ERROR });
      }
    );
  }
  public handleFileInput(data: any): void {
    this.fileToUpload = data.target.files.item(0);
  }

  public xhrCall(url: string, formDataObj: any): Observable<Object> {
    return new Observable((observer: Observer<object>) => {
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === AppConstants.HTTP_CODES.STATUS_OK || xhr.status === AppConstants.HTTP_CODES.CREATION_SUCCEEDED) {
            observer.next(xhr);
          } else {
            observer.error(xhr);
          }
        }
      };
      let body: FormData = new FormData();
      for (let key in formDataObj) {
        if (key === "tags") {
          (formDataObj[key] ?? []).forEach((tagValue: string) => {
            body.append('tags[]',  tagValue);
          });
        } else {
          body.append(key, formDataObj[key]);
        }
      }
      xhr.open('POST', url, true);
      xhr.withCredentials = true;
      xhr.send(body);
    });
  }
}
