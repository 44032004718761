import { inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ITileConfig } from "@shared/components/interfaces/tile.interface";
import { TileService } from "@shared/services/tile/tile.service";
import { catchError, map } from "rxjs/operators";
import { AppConstants } from "@core/constants";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MyInscydService } from "@shared/services/my-inscyd/my-inscyd.service";

@Injectable()
export class MyInscydFeatureItemResolver {
  private tileService: TileService = inject(TileService);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private myInscydService: MyInscydService = inject(MyInscydService);
  private customRendererService: CustomRendererService = inject(CustomRendererService);

  public resolve(): Observable<ITileConfig[] | null> {
    return this.myInscydService.getMyInscydFeatureList()
      .pipe(
        map((featureList: any): ITileConfig[] => {
          return this.tileService.getGeneratedTileConfigBasedOnResponse(featureList.results, 'View Details', '/home/my-inscyd/feature-detail')
        }),
        catchError(error => {
          this.customRendererService.hide('.loading_layout');
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          return of(null);
        }
        )
      )
  }
}
