import { Inject, Injectable, Optional } from "@angular/core";
import { Params } from "@angular/router";

type FeatureFlagValue = boolean | number | string | null | undefined;
type ValueMapper = (value: FeatureFlagValue) => FeatureFlagValue;

export const booleanStringToBoolean = (value: FeatureFlagValue): boolean => (value as string).toLocaleLowerCase() === "true";

export abstract class FeatureFlagService {
  abstract setConfig(config: any): FeatureFlagService;
  abstract getValue(featureFlag: string, valueMapper?: ValueMapper): FeatureFlagValue;
}

@Injectable()
export class QueryParamsFeatureFlagConfig {
  params: Params;
}

@Injectable()
export class QueryParamsFeatureFlagService implements FeatureFlagService {
  public config: QueryParamsFeatureFlagConfig;

  constructor(@Inject(QueryParamsFeatureFlagConfig) @Optional() config: QueryParamsFeatureFlagConfig) {
    this.config = config;
  }

  setConfig(config: QueryParamsFeatureFlagConfig): QueryParamsFeatureFlagService {
    this.config = config;
    return this;
  }

  getValue(featureFlag: string, valueMapper?: ValueMapper): FeatureFlagValue {
    const flagValue: FeatureFlagValue = (this.config.params ? this.config.params[featureFlag] : undefined) as FeatureFlagValue;
    if (flagValue == null) {
      return undefined;
    }
    return valueMapper ? valueMapper(flagValue) : flagValue;
  }
}
