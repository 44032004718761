<app-header
  [currentTestId]="currentTestId"
  (menuBarChange)="toggleMenuBar()"
></app-header>
<div id="loading" class="loading_layout" style="display: none">
  <div
    id="loading_wrapper"
    class="loading_wrapper"
    (click)="hideLoading()"
    title="Click to hide loading"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
<mat-sidenav-container autosize>
  <mat-sidenav #sidenav mode="side" opened="true">
    <app-sidebar-menu [currentTestId]="currentTestId"></app-sidebar-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="app-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
