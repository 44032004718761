import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from "@angular/core";
import { AppConstants } from "@core/constants";
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";
import { ActivatedFeatureInterface } from "@shared/interfaces/activated-feature.interface";

@Component({
  selector: 'app-active-feature',
  templateUrl: './active-feature.component.html',
  styleUrls: ['./active-feature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export  class ActiveFeatureComponent implements OnInit {
  private featureStoreService: FeatureStoreService = inject(FeatureStoreService);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  public constant = AppConstants;
  public listActiveFeature: ActivatedFeatureInterface[] = [];

  public ngOnInit(): void {
    this.getFeatureList();
  }

  public getFeatureList(): void {
    this.featureStoreService.getActivatedFeatureList()
      .subscribe((featureList: ActivatedFeatureInterface[]): void => {
        this.listActiveFeature = featureList.map((feature: ActivatedFeatureInterface) => {
          return {
            ...feature,
            isShowDetail: false,
            period_type_clone: feature.period_type,
          };
        });
        this.cdr.markForCheck();

      });
  }
}
