import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSnackBarRef, TextOnlySnackBar } from "@angular/material/snack-bar";
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';
import { BillingPeriodDetail, GroupResultDto, PricingPlansDto } from '@core/services/pricing-plans/pricing-plans-dto';
import { PricingPlansService } from '@core/services/pricing-plans/pricing-plans.service';
import { RightConstants } from '@modules/administration/rights-management/constants/right-constants';
import { IRightList } from '@modules/administration/rights-management/rights-management-dialog/rights-management-dialog.component';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import * as _ from 'lodash-es';
import { combineLatest } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-pricing-plans-dialog',
  templateUrl: './pricing-plans-dialog.component.html',
  styleUrls: ['./pricing-plans-dialog.component.scss'],
})
export class PricingPlansDialogComponent extends AppBaseComponent {
  public included_items = AppConstants.ITEMS_INCLUDED;
  public displayed_items = AppConstants.ITEMS;
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public max_item: number = 0;
  public from_user: number = 0;
  public from_athlete: number = 0;
  public from_test_la: number = 0;
  public from_test_map: number = 0;
  public from_test_manual: number = 0;
  public from_test_virtual: number = 0;
  public from_test_event: number = 0;
  public from_test_result: number = 0;
  public isShowExcessRegions: boolean = false;
  public isShowColumns: boolean = false;
  public isShowPricingRegions: boolean = false;
  public isShowPlanSetting: boolean = true;
  public isShowDurationSetting: boolean = true;
  public isShowAvailabilitySetting: boolean = true;
  public isShowPricingSetting: boolean = true;
  public isShowFeeSetting: boolean = true;
  public isShowExcessItems: boolean = true;
  public isShowRatios: boolean = true;
  public isShowDiscount: boolean = true;
  public isShowThreshold: boolean = true;
  public isShowItemDisplaySetting: boolean = true;
  public firstForm: UntypedFormGroup = this.fb.group({});
  public availabilityForm: UntypedFormGroup = {} as UntypedFormGroup;
  public planForm: UntypedFormGroup = this.fb.group({});
  public durationForm: UntypedFormGroup = this.fb.group({});
  public pricingForm: UntypedFormGroup = {} as UntypedFormGroup;
  public priceForm: UntypedFormGroup = {} as UntypedFormGroup;
  public feeForm: UntypedFormGroup = this.fb.group({});
  public alternativeItemForm: UntypedFormGroup = {} as UntypedFormGroup;
  public ratiosForm: UntypedFormGroup = {} as UntypedFormGroup;
  public ratioForm: UntypedFormGroup = {} as UntypedFormGroup;
  public excessForm: UntypedFormGroup = {} as UntypedFormGroup;
  public lotExcessForm: UntypedFormGroup = {} as UntypedFormGroup;
  public discountsForm: UntypedFormGroup = {} as UntypedFormGroup;
  public discountForm: UntypedFormGroup = {} as UntypedFormGroup;
  public thresholdForm: UntypedFormGroup = this.fb.group({});
  public lotForm: UntypedFormGroup = this.fb.group({});
  public itemDisplayForm: UntypedFormGroup = this.fb.group({});
  public productOptions: any[] = [];
  public featureOptions: any[] = [];
  public accountOptions: any[] = [];
  public regionOptions: any[] = [];
  public organizationOptions: any[] = [];
  public groupOptions: any[] = [];
  public activationOptions: any[] = [];
  public itemDisplayOptions: any[] = [];
  public itemsIncludedOptions: any[] = [];
  public bundledOptions: any[] = [];
  public periodTypes: any;
  public items: any;
  public activation_items: any;
  public discountTypes: any;
  public isShowItemUser: boolean = false;
  public isShowItemAthlete: boolean = false;
  public isShowItemLA: boolean = false;
  public isShowItemMap: boolean = false;
  public isShowItemManual: boolean = false;
  public isShowItemVirtual: boolean = false;
  public isShowItemEvent: boolean = false;
  public isShowItemResult: boolean = false;
  public isShowItemMetabolic: boolean = false;
  public isShowItemReports: boolean = false;
  public showRightManagement: boolean = true;
  public rightList: Array<IRightList> = [];
  public rights: any;
  public rightOptions: any;
  public rightManagemntToggleButton: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PricingPlansDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly snackBar: MatSnackBar,
    private readonly planService: PricingPlansService,
    private readonly fb: UntypedFormBuilder,
    private readonly dialog: MatDialog,
    private apiService: ApiService
  ) {
    super();
  }

  public onInitPage(): void {
    this.initForm();
    this.initOptionAndValue();
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public prepareData(data: any, dataOptions: any) {
    return dataOptions.filter((item: any) => data?.includes(item.value));
  }

  private mappingOptionValue(item: any, param: string) {
    return {
      value: item?.id,
      name: item[param],
    };
  }

  public initForm(): void {
    this.rightOptions = _.cloneDeepWith(RightConstants.DEFAULT_RIGHTS);
    this.rightList = _.cloneDeepWith(
      RightConstants.RIGHTS_LIST_DISPLAY_PRODUCT_DIALOGS
    );
    this.rights = this.rightOptions;
    this.resetRights();
    this.rightManagemntToggleButton = this.data.isEdit;

    this.firstForm = this.fb.group({
      name: new UntypedFormControl('', Validators.required),
      is_publish: new UntypedFormControl(false),
      group: new UntypedFormControl(''),
      product_ids: new UntypedFormControl(''),
      feature_ids: new UntypedFormControl([]),
      is_accumulated: new UntypedFormControl(false),
      is_show_setting: new UntypedFormControl(false),
      rightManagement: new UntypedFormControl(this.rights),
    });
    this.planForm = this.fb.group({
      description: new UntypedFormControl('', Validators.required),
      features: this.fb.array([this.featureForm]),
    });
    this.availabilityForm = this.fb.group({
      account_types: new UntypedFormControl([]),
      regions: new UntypedFormControl([]),
      organizations: new UntypedFormControl([]),
    });
    this.durationForm = this.fb.group({
      renewal: new UntypedFormControl('1', Validators.required),
      renewal_unit: new UntypedFormControl(1),
      cancel_notice_time: new UntypedFormControl(0),
      minimum_contract_duration: new UntypedFormControl(),
      billing_period_details: this.prepareDurationDetails(true),
    });
    this.priceForm = this.fb.group({
      name_link_item: new UntypedFormControl(''),
      master_item: new UntypedFormControl(2),
      activation_item: new UntypedFormControl(''),
      current_period: new UntypedFormControl(''),
      idle_time_period: new UntypedFormControl(''),
      idle_time_unit: new UntypedFormControl(''),
      max_item: new UntypedFormControl(0),
      lots: this.fb.array([]),
    });
    this.pricingForm = this.fb.group({
      link_items: this.fb.array([]),
    });
    this.lotForm = this.fb.group({
      from_link_item: new UntypedFormControl(0),
      to_link_item: new UntypedFormControl(0),
      is_once_link_item: new UntypedFormControl(false),
      lot_prices: this.fb.array([]),
    });
    this.feeForm = this.fb.group({
      include_items: new UntypedFormControl(''),
      max_athlete: new UntypedFormControl(),
      max_test_event: new UntypedFormControl(),
      max_test_la: new UntypedFormControl(),
      max_test_manual: new UntypedFormControl(),
      max_test_map: new UntypedFormControl(),
      max_test_result: new UntypedFormControl(),
      max_test_virtual: new UntypedFormControl(),
      max_user: new UntypedFormControl(),
      plan_prices: this.fb.array([]),
      alternative_items: this.fb.array([]),
      billing_period_details: this.prepareItemCounts(true),
    });
    this.alternativeItemForm = this.fb.group({
      alternative_item: new UntypedFormControl(''),
    });
    this.itemDisplayForm = this.fb.group({
      display_items: new UntypedFormControl(''),
    });
    this.ratioForm = this.fb.group({
      item1: new UntypedFormControl(''),
      item2: new UntypedFormControl(''),
      item1_quantity: new UntypedFormControl(0),
      period_value: new UntypedFormControl(''),
      period_type: new UntypedFormControl(''),
      item2_quantity: new UntypedFormControl(1),
    });
    this.ratiosForm = this.fb.group({
      ratios: this.fb.array([]),
    });
    this.excessForm = this.fb.group({
      lots_excess: this.fb.array([]),
    });
    this.lotExcessForm = this.fb.group({
      from_link_item: new UntypedFormControl(0),
      from_user: new UntypedFormControl(0),
      from_athlete: new UntypedFormControl(0),
      from_test_la: new UntypedFormControl(0),
      from_test_map: new UntypedFormControl(0),
      from_test_manual: new UntypedFormControl(0),
      from_test_virtual: new UntypedFormControl(0),
      from_test_event: new UntypedFormControl(0),
      from_test_result: new UntypedFormControl(0),
      to_link_item: new UntypedFormControl(0),
      to_user: new UntypedFormControl(''),
      to_athlete: new UntypedFormControl(''),
      to_test_la: new UntypedFormControl(''),
      to_test_map: new UntypedFormControl(''),
      to_test_manual: new UntypedFormControl(''),
      to_test_virtual: new UntypedFormControl(''),
      to_test_event: new UntypedFormControl(''),
      to_test_result: new UntypedFormControl(''),
      is_once_link_item: new UntypedFormControl(false),
      is_once_user: new UntypedFormControl(false),
      is_once_athlete: new UntypedFormControl(false),
      is_once_la: new UntypedFormControl(false),
      is_once_map: new UntypedFormControl(false),
      is_once_manual: new UntypedFormControl(false),
      is_once_virtual: new UntypedFormControl(false),
      is_once_event: new UntypedFormControl(false),
      is_once_result: new UntypedFormControl(false),
      lot_prices: this.fb.array([]),
    });
    this.discountForm = this.fb.group({
      discount_value: new UntypedFormControl('', [Validators.required]),
      discount_type: new UntypedFormControl(1, [Validators.required]),
      purchased_day: new UntypedFormControl('', [Validators.required]),
      account_types: new UntypedFormControl([]),
      regions: new UntypedFormControl([]),
      organizations: new UntypedFormControl([]),
      valid_until: new UntypedFormControl(new Date()),
    });
    this.discountsForm = this.fb.group({
      discounts: this.fb.array([]),
    });
    this.thresholdForm = this.fb.group({
      notification_athlete: new UntypedFormControl(),
      notification_test_event: new UntypedFormControl(),
      notification_test_la: new UntypedFormControl(),
      notification_test_manual: new UntypedFormControl(),
      notification_test_map: new UntypedFormControl(),
      notification_test_result: new UntypedFormControl(),
      notification_test_virtual: new UntypedFormControl(),
      notification_user: new UntypedFormControl(),
    });

    if (this.data.isEdit) {
      try {
        this.rights = JSON.parse(this.data.plan.right_management);
      } catch (error) {
        this.rights = this.rightOptions;
        this.resetRights();
      }

      this.planForm = this.fb.group({
        description: new UntypedFormControl(
          this.data.plan.description,
          Validators.required
        ),
        features: this.prepareFeatures(),
      });
      this.durationForm = this.fb.group({
        renewal: new UntypedFormControl(this.data.plan.renewal, Validators.required),
        renewal_unit: new UntypedFormControl(this.data.plan.renewal_unit),
        cancel_notice_time: new UntypedFormControl(this.data.plan.cancel_notice_time),
        minimum_contract_duration: new UntypedFormControl(this.data.plan.minimum_contract_duration),
        billing_period_details: this.prepareDurationDetails(),
      });
      this.thresholdForm = this.fb.group({
        notification_athlete: new UntypedFormControl(
          this.data.plan.notification_athlete
        ),
        notification_test_event: new UntypedFormControl(
          this.data.plan.notification_test_event
        ),
        notification_test_la: new UntypedFormControl(
          this.data.plan.notification_test_la
        ),
        notification_test_manual: new UntypedFormControl(
          this.data.plan.notification_test_manual
        ),
        notification_test_map: new UntypedFormControl(
          this.data.plan.notification_test_map
        ),
        notification_test_result: new UntypedFormControl(
          this.data.plan.notification_test_result
        ),
        notification_test_virtual: new UntypedFormControl(
          this.data.plan.notification_test_virtual
        ),
        notification_user: new UntypedFormControl(this.data.plan.notification_user),
      });
    }
  }

  private initOptionAndValue(): void {
    this.periodTypes = AppConstants.PERIOD_TYPES;
    this.items = AppConstants.ITEMS;
    this.activation_items = AppConstants.ACTIVATION_ITEMS;
    this.discountTypes = AppConstants.DISCOUNT_TYPES;
    this.activationOptions = this.activation_items;
    this.itemDisplayOptions = AppConstants.ITEMS;
    this.itemsIncludedOptions = AppConstants.ITEMS_INCLUDED;

    this.planService.getGroups().subscribe((res: GroupResultDto): void => {
      this.groupOptions = res.items;
    });

    combineLatest([
      this.apiService.get('features/'),
      this.apiService.get('products/'),
      this.apiService.get('account-type/'),
      this.apiService.get('region-pricing/'),
      this.apiService.get('organizations/'),
    ]).subscribe(
      ([features, resProducts, resAccountTypes, resRegions, resOrganizations]: any): void => {
        this.featureOptions = features?.results?.map((item: any) => {
          return this.mappingOptionValue(item, 'name');
        });

        this.productOptions = resProducts?.results?.map((item: any) => {
          return this.mappingOptionValue(item, 'name');
        });

        this.accountOptions = resAccountTypes?.results?.map((item: any) => {
          return this.mappingOptionValue(item, 'name');
        });

        this.regionOptions = resRegions?.results?.map((item: any) => {
          return this.mappingOptionValue(item, 'name');
        });
        this.organizationOptions = resOrganizations?.results?.map(
          (item: any) => {
            return this.mappingOptionValue(item, 'name');
          }
        );

        if (this.data.isEdit) {
          this.prepareInputs();
          this.firstForm = this.fb.group({
            name: new UntypedFormControl(this.data.plan.name, Validators.required),
            is_publish: new UntypedFormControl(this.data.plan.is_publish),
            group: new UntypedFormControl(this.data.plan.group),
            product_ids: new UntypedFormControl(this.prepareProducts()),
            feature_ids: new UntypedFormControl(this.prepareFeatureList()),
            is_accumulated: new UntypedFormControl(this.data.plan.is_accumulated),
            is_show_setting: new UntypedFormControl(this.data.plan.is_show_setting ?? false),
            rightManagement: new UntypedFormControl(this.data.plan.right_management ?? this.rights),
            settingsRightManagement: new UntypedFormControl(false),
          });
          this.availabilityForm.setValue({
            account_types: this.prepareData(
              this.data.plan.account_types,
              this.accountOptions
            ),
            regions: this.prepareData(
              this.data.plan.regions,
              this.regionOptions
            ),
            organizations: this.prepareData(
              this.data.plan.organizations,
              this.organizationOptions
            ),
          });
          this.itemDisplayForm = this.fb.group({
            display_items: new UntypedFormControl(this.prepareDisplayItems()),
          });
          this.discountsForm = this.fb.group({
            discounts: this.prepareDiscounts(),
          });
          this.ratiosForm = this.fb.group({
            ratios: this.prepareRatios(),
          });
          this.pricingForm = this.fb.group({
            link_items: this.preparePricing(),
          });
          this.excessForm = this.fb.group({
            lots_excess: this.prepareExcessItems(),
          });
          this.feeForm = this.fb.group({
            is_accumulated: new UntypedFormControl(this.data.plan.is_accumulated),
            include_items: new UntypedFormControl(this.prepareIncludeItems()),
            max_athlete: new UntypedFormControl(this.data.plan.max_athlete),
            max_test_event: new UntypedFormControl(this.data.plan.max_test_event),
            max_test_la: new UntypedFormControl(this.data.plan.max_test_la),
            max_test_manual: new UntypedFormControl(this.data.plan.max_test_manual),
            max_test_map: new UntypedFormControl(this.data.plan.max_test_map),
            max_test_result: new UntypedFormControl(this.data.plan.max_test_result),
            max_test_virtual: new UntypedFormControl(this.data.plan.max_test_virtual),
            max_user: new UntypedFormControl(this.data.plan.max_user),
            plan_prices: this.fb.array([]),
            alternative_items: this.prepareAlternativeItems(),
            billing_period_details: this.prepareItemCounts(),
          });
          this.preparePrices();
        }
      }
    );
  }

  public prepareProducts(): any[] {
    const product_ids = this.data.plan.product_ids?.split('|');

    return this.productOptions.filter((item) =>
      product_ids?.includes(item.value.toString())
    );
  }

  public prepareFeatureList(): any[] {
    return this.featureOptions.filter((item) =>
      this.data.plan.bundled_features.includes(item.value)
    );
  }

  public get features(): UntypedFormArray {
    return this.planForm.get('features') as UntypedFormArray;
  }

  public addFeatureField(): void {
    const feature: UntypedFormGroup = this.fb.group({
      feature: new UntypedFormControl('', [Validators.required]),
    });
    this.features.push(feature);
  }

  public removeFeature(index: number): void {
    if (this.features.length > 1) this.features.removeAt(index);
    else this.features.patchValue([{ phoneNo: null, emailAddr: null }]);
  }

  public featureForm: UntypedFormGroup = this.fb.group({
    feature: new UntypedFormControl('', Validators.required),
  });

  public prepareFeatures(): UntypedFormArray {
    const features = this.data.plan.features.split('|');
    const featureForms = this.fb.array([]);

    features.map((feature: string): void => {
      featureForms.push(
        this.fb.group({
          feature: new UntypedFormControl(feature, Validators.required),
        })
      );
    });

    return featureForms;
  }

  public get alternative_items(): UntypedFormArray {
    return this.feeForm.get('alternative_items') as UntypedFormArray;
  }

  public addBundledField(): void {
    const item: UntypedFormGroup = this.fb.group({
      alternative_item: new UntypedFormControl(''),
    });
    this.alternative_items.push(item);
  }

  public removeBundled(index: number): void {
    this.alternative_items.removeAt(index);
  }

  public onSelectBundled(value: any, index: number): void {
    this.getBundledFormControl('alternative_item', index)?.setValue(value);
  }

  public getBundledFormControl(controlName: string, index: number) {
    return (this.feeForm.get('alternative_items') as UntypedFormArray)?.controls[
      index
      ].get(controlName);
  }

  prepareAlternativeItems(): UntypedFormArray {
    const alternative_items = this.data.plan.alternative_items;
    const itemsFormsArray: UntypedFormArray = this.fb.array([]);

    alternative_items.map((item: any): void => {
      this.bundledOptions = this.prepareIncludeItems();
      const formGroupControls: Record<string, any> = {
        alternative_item: new UntypedFormControl(
          this.bundledOptions.filter((i: any) =>
            item.alternative_item.split('|')?.includes(i.id.toString())
          )
        ),
      };

      if (item.id) {
        formGroupControls["id"] = new UntypedFormControl(item.id);
      }

      const formGroup: UntypedFormGroup = this.fb.group(formGroupControls);
      itemsFormsArray.push(formGroup);
    });
    return itemsFormsArray;
  }

  public get link_items(): UntypedFormArray {
    return this.pricingForm.get('link_items') as UntypedFormArray;
  }

  public get pricing_form(): UntypedFormArray {
    return this.pricingForm.get('link_items') as UntypedFormArray;
  }

  public removeLinkItem(index: number): void {
    this.link_items.removeAt(index);
  }

  public copyLinkForm(link: any): void {
    const hasLots: boolean = this.hasItemsLots();

    if (!hasLots) {
      this.snackBar.open('Please create at least one lot for linked item to proceed.', 'OK', this.constant.TOAST_CONFIG.ERROR);

      return;
    }

    const linkCopy = _.cloneDeep(link);
    this.link_items.push(linkCopy);
  }

  public maxChange(event: any, index: any): void {
    if (
      (this.link_items.at(index).get('lots') as UntypedFormArray).value.length != 0
    ) {
      (this.link_items.at(index).get('lots') as UntypedFormArray).controls[0]
        .get('from_link_item')
        ?.setValue(parseInt(event?.target.value) + 1);
    }
  }

  public lotToChange(event: any, index: any, index2: any): void {
    if ((this.link_items.at(index).get('lots') as UntypedFormArray).value.length > 1) {
      (this.link_items.at(index).get('lots') as UntypedFormArray).controls[index2]
        ?.get('from_link_item')
        ?.setValue(parseInt(event?.target.value) + 1);
    }
  }

  public hasItemsLots(): boolean {
    return this.link_items.value.every((item: any) => item.lots.length);
  }

  public addLinkItemForm(): void {
    const hasLots: boolean = this.hasItemsLots();

    if (hasLots) {
      const activationIt: UntypedFormGroup = this.fb.group({
        name_link_item: new UntypedFormControl(''),
        master_item: new UntypedFormControl(2),
        activation_item: new UntypedFormControl(''),
        current_period: new UntypedFormControl(''),
        idle_time_period: new UntypedFormControl(''),
        idle_time_unit: new UntypedFormControl(''),
        max_item: new UntypedFormControl(''),
        lots: this.fb.array([]),
      });
      this.link_items.push(activationIt);
    } else {
      this.snackBar.open('Add lot for the previous link item.', 'OK', this.constant.TOAST_CONFIG.ERROR);
    }
  }

  public onSelectActivationItem(value: any, index: number): void{
    this.getFormControl('activation_item', index)?.setValue(value);
  }

  public getFormControl(controlName: string, index: number) {
    return (this.pricingForm.get('link_items') as UntypedFormArray).controls[index].get(controlName);
  }

  public preparePricing(): UntypedFormArray {
    const link_items = this.data.plan.link_items;
    const pricingFormsArray: UntypedFormArray = this.fb.array([]);

    link_items.map((item: any): void => {
      const formGroupControls: Record<string, any> = {
        name_link_item: new UntypedFormControl(item.name_link_item),
        master_item: new UntypedFormControl(item.master_item),
        activation_item: new UntypedFormControl(
          this.activationOptions.filter((i: any) =>
            item.activation_item.split('|')?.includes(i.id.toString())
          )
        ),
        current_period: new UntypedFormControl(item.current_period),
        idle_time_period: new UntypedFormControl(item.idle_time_period),
        idle_time_unit: new UntypedFormControl(item.idle_time_unit),
        max_item: new UntypedFormControl(item.max_item),
        lots: this.prepareLots(item),
      };

      if (item.id) {
        formGroupControls["id"] = new UntypedFormControl(item.id);
      }

      const formGroup: UntypedFormGroup = this.fb.group(formGroupControls);
      pricingFormsArray.push(formGroup);
    });

    return pricingFormsArray;
  }

  public getLots(index: number): UntypedFormArray {
    return this.link_items.at(index).get('lots') as UntypedFormArray;
  }

  public getLinkLotPrices(index: number, index2: number): UntypedFormArray {
    return this.getLots(index).at(index2).get('lot_prices') as UntypedFormArray;
  }

  public addLinkPriceField(link_item?: number): void {
    const index: number = this.link_items.length - 1;
    const index2: number = this.getLots(index).length - 1;
    this.isShowPricingRegions = true;

    for (let region of this.availabilityForm.get('regions')?.value) {
      for (let i = 1; i < 5; i++) {
        const lotPrice: UntypedFormGroup = this.fb.group({
          link_item: new UntypedFormControl(link_item ? link_item : 0),
          region_name: new UntypedFormControl(region.name),
          region_id: new UntypedFormControl(region.value),
          type_name: new UntypedFormControl(this.getLinkPriceType(i)),
          type_value: new UntypedFormControl(i),
        });
        this.getLinkLotPrices(index, index2).push(lotPrice);
      }
    }
  }

  public getLinkPriceType(type: number): string {
    const text: string = 'Fee for current period if plan is ';
    if (type === 1) {
      return text + 'yearly';
    } else if (type === 2) {
      return text + 'quarterly';
    } else if (type === 3) {
      return text + 'monthly';
    } else {
      return 'Item';
    }
  }

  public removeLotItem(index: number, index2: number): void {
    const i: number = this.link_items.controls[index2].get('lots')?.value.length - 1;
    if (index < i) {
      this.snackBar.open('Please remove the last one first.', 'OK', this.constant.TOAST_CONFIG.ERROR);
    } else {
      (this.link_items.controls[index2].get('lots') as UntypedFormArray).removeAt(
        index
      );
    }
  }

  public checkLots(): number {
    if (this.link_items.value.length != 0) {
      const index: number = this.pricingForm.value.link_items.length - 1;
      if (this.pricingForm.value.link_items[index].lots.length == 0) {
        this.max_item = this.link_items.value[0].max_item + 1;

        return this.max_item;
      } else {
        const indexP: number = this.pricingForm.value.link_items.length - 1;
        const i: number = this.pricingForm.value.link_items[index].lots.length - 1;
        this.max_item =
          parseInt(this.link_items.value[index].lots[i].to_link_item, 10) + 1;

        return this.max_item;
      }
    } else {
      return 0;
    }
  }

  public addLotForm(index: any): void {
    const index1: number = this.pricingForm.value.link_items.length - 1;
    const i: number = this.pricingForm.value.link_items[index1].lots.length - 1;
    if (
      this.pricingForm.value.link_items[index1].lots.length != 0 &&
      this.link_items.value[index1].lots[i].to_link_item == 0
    ) {
      this.snackBar.open('Please fill out the previous lot and make sure TO must be greater than FROM.', 'OK', this.constant.TOAST_CONFIG.ERROR);
    } else {
      const lot: UntypedFormGroup = this.fb.group({
        from_link_item: new UntypedFormControl(this.checkLots()),
        to_link_item: new UntypedFormControl(0),
        is_once_link_item: new UntypedFormControl(false),
        lot_prices: this.fb.array([]),
      });
      (this.link_items.controls[index].get('lots') as UntypedFormArray).push(lot);
      this.addLinkPriceField();
    }
  }

  public prepareLots(item: any): UntypedFormArray {
    const lotFormsArray: UntypedFormArray = this.fb.array([]);
    item.lots.map((lot: any): void => {
      const formGroupControls: Record<string, any> = {
        from_link_item: new UntypedFormControl(lot.from_link_item),
        to_link_item: new UntypedFormControl(lot?.to_link_item),
        is_once_link_item: new UntypedFormControl(lot?.is_once_link_item),
        lot_prices: this.prepareLinkPrices(lot),
      };

      if (lot.id) {
        formGroupControls["id"] = new UntypedFormControl(lot.id)
      }

      const formGroup: UntypedFormGroup = this.fb.group(formGroupControls);
      lotFormsArray.push(formGroup);
    });

    return lotFormsArray;
  }

  public prepareLinkPrices(item: any): UntypedFormArray {
    this.isShowPricingRegions = true;
    const lotFormsArray: UntypedFormArray = this.fb.array([]);
    item.lot_prices.map((lot: any): void => {
      const formGroupControls: Record<string, any> = {
        link_item: new UntypedFormControl(lot.link_item),
        region_name: new UntypedFormControl(
          this.regionOptions.find(
            (region: any): boolean => region.value == lot.region
          ).name
        ),
        region_id: new UntypedFormControl(lot.region),
        type_name: new UntypedFormControl(this.getLinkPriceType(lot.type)),
        type_value: new UntypedFormControl(lot.type),
      };

      if (lot.id) {
        formGroupControls["id"] = new UntypedFormControl(lot.id);
      }

      const formGroup = this.fb.group(formGroupControls);
      lotFormsArray.push(formGroup);
    });

    return lotFormsArray;
  }

  public prepareIncludeItems() {
    const item_ids = this.data.plan?.include_items?.split('|');

    return this.included_items.filter((item) =>
      item_ids.includes(item.id.toString())
    );
  }

  public preparePrices(): void {
    this.regionOptions.map((region: any): void => {
      this.data.plan.plan_prices.map((price: any): void => {
        if (price.region === region.value) {
          this.addPriceField(region, price.annual, price.quarter, price.month, price.id);
        }
      });
    });
  }

  public addPriceField(region: any, annual?: number, quarter?: number, month?: number, id?: number): void {
    const priceFromControls: Record<string, any> = {
      annual: new UntypedFormControl(annual ? annual : 0),
      quarter: new UntypedFormControl(quarter ? quarter : 0),
      month: new UntypedFormControl(month ? month : 0),
      region_name: new UntypedFormControl(region.name),
      region_id: new UntypedFormControl(region.value),
    };

    if (id) {
      priceFromControls["id"] = new UntypedFormControl(id);
    }
    const price: UntypedFormGroup = this.fb.group(priceFromControls);
    this.plan_prices.push(price);
  }

  public removePriceField(region: any): void {
    this.plan_prices.removeAt(
      this.plan_prices.value.findIndex(
        (price: any): boolean => price.region_id === region
      )
    );
  }

  public getDifference(a: any[], b: any[]): any {
    return a.filter((object1) => {
      return !b.some((object2) => {
        return object1.value === object2.value.region_id;
      });
    });
  }

  public getLinkedItemDifference(a: any[], b: any[]): any {
    return a.filter((object1) => {
      return !b.some((object2): boolean => {
        return object1.value === object2.region_id;
      });
    });
  }

  public getDifferenceWhenRemoving(a: any[], b: any[]): any {
    return a.filter((object1) => {
      return !b.some((object2): boolean => {
        return object2.value === object1.value.region_id;
      });
    });
  }

  public getDifferenceWhenRemovingLot(lotRegionList: any, regionList: any[], isLinkItems: boolean = false): any {
    let lotRegionIdList;

    if (!isLinkItems) {
      lotRegionIdList = lotRegionList.value.lot_prices.map((item: any) => item.region_id);
    } else if (isLinkItems) {
      lotRegionIdList = lotRegionList.map((item: any) => item.region_id);
    }

    lotRegionIdList = lotRegionIdList.filter((value: any, index: any, array: any) => {
      return array.indexOf(value) === index;
    });
    const regionIdList: any[] = regionList.map((item: any) => item.value);
    let removedRegionId: number | undefined = undefined;

    lotRegionIdList.forEach((item: any): void => {
      if (!regionIdList.includes(item)) {
        removedRegionId = item;
      }
    });

    return removedRegionId;
  }

  public get plan_prices(): UntypedFormArray {
    return this.feeForm.get('plan_prices') as UntypedFormArray;
  }

  public get billingPeriodDetails(): UntypedFormArray {
    return this.feeForm.get('billing_period_details') as UntypedFormArray;
  }

  public get durationPeriodDetails(): UntypedFormArray {
    return this.durationForm.get('billing_period_details') as UntypedFormArray;
  }

  public get lots(): UntypedFormArray {
    return this.excessForm.get('lots_excess') as UntypedFormArray;
  }

  public prepareDisplayItems() {
    const item_ids = this.data.plan?.display_items?.split('|');

    return this.displayed_items.filter((item) =>
      item_ids?.includes(item.id.toString())
    );
  }

  public onSelectRegion(valueList: any): void {
    this.availabilityForm.get('regions')?.setValue(valueList);
    if (valueList.length == 0) {
      this.isShowColumns = false;
      this.isShowPricingRegions = false;
      this.isShowExcessRegions = false;
    } else {
      this.isShowColumns = true;
      this.isShowPricingRegions = true;
      this.isShowExcessRegions = true;
    }

    if (valueList.length > this.plan_prices.controls.length) {
      this.addPriceField(
        this.getDifference(valueList, this.plan_prices.controls)[0]
      );
    } else if (valueList.length < this.plan_prices.controls.length) {
      this.removePriceField(
        this.getDifferenceWhenRemoving(this.plan_prices.controls, valueList)[0]
          .value.region_id
      );
    }

    if (this.lots.value.length) {
      if (valueList.length > this.lots.value[0].lot_prices.length / 4) {
        this.addLotPriceField(this.getDifference(valueList, this.lots.controls)[0]);
      } else if (valueList.length < this.lots.value[0].lot_prices.length / 4) {
        this.removeLotPriceField(this.getDifferenceWhenRemovingLot(this.lots.controls[0], valueList));
      }
    }

    if (this.link_items.value.length) {
      if (this.link_items.value[0].lots.length) {
        if (valueList.length > this.link_items.value[0].lots[0].lot_prices.length / 4) {
          this.addPricingLot(this.getLinkedItemDifference(valueList, this.link_items.value[0].lots[0].lot_prices)[0]);
        } else if (valueList.length < this.link_items.value[0].lots[0].lot_prices.length / 4) {
          this.removePricingLotField(this.getDifferenceWhenRemovingLot(this.link_items.value[0].lots[0].lot_prices, valueList, true));
        }
      }
    }
  }

  public getLotPrices(index: number): UntypedFormArray {
    return this.lots_excess.at(index).get('lot_prices') as UntypedFormArray;
  }

  public getPricingLotById(id: number): UntypedFormArray {
    return this.pricing_form.at(id) as UntypedFormArray;
  }

  public removeLotPriceField(region: any): void {
    this.lots_excess.controls.forEach((control: any): void => {
      const controlIndexList: Array<number> = [];
      control.value.lot_prices.forEach((item: any, index: number): void => {
        if (item.region_id === region) {
          controlIndexList.push(index);
        }
      });

      controlIndexList.forEach(() => {
        control.controls.lot_prices.removeAt(controlIndexList[0]);
      });
    });
  }

  public removePricingLotField(region: any): void {
    const index: number = this.link_items.length;
    let regionIdList: Array<number> = [];

    for (let j = 0; j < index; j++) {
      this.getPricingLotById(j).value.lots.forEach((item: any, index: number): void => {
        regionIdList = [];
        item.lot_prices.forEach((lotPrice: any, indexPrice: number) => {
          if (lotPrice.region_id === region) {
            regionIdList.push(indexPrice);
          }
        });

        const linkItems: UntypedFormArray = this.pricingForm.get('link_items') as UntypedFormArray;
        const lots: UntypedFormArray = linkItems.at(j).get('lots') as UntypedFormArray;
        const lotPrices: UntypedFormArray = lots.at(index).get('lot_prices') as UntypedFormArray;


        regionIdList.forEach((): void => {
          lotPrices.removeAt(regionIdList[0]);
        });
      });
    }
  }

  public getPriceType(type: number) {
    if (type === 1) {
      return 'Year';
    } else if (type === 2) {
      return 'Quarter';
    } else if (type === 3) {
      return 'Month';
    } else {
      return 'Item';
    }
  }

  public addLotPriceField(
    user?: number,
    athlete?: number,
    test_la?: number,
    test_map?: number,
    test_manual?: number,
    test_virtual?: number,
    test_result?: number,
    test_event?: number): void {
    const index: number = this.lots_excess.length;
    for (let j = 0; j < index; j++) {
      for (let region of this.availabilityForm.get('regions')?.value) {
        let isExistRegion = this.getLotPrices(j).value.some((item: any): boolean => item.region_id === region.value);
        if (!isExistRegion) {
          for (let i = 1; i < 5; i++) {
            const lotPrice: UntypedFormGroup = this.fb.group({
              user: new UntypedFormControl(user ? user : 0),
              athlete: new UntypedFormControl(athlete ? athlete : 0),
              test_la: new UntypedFormControl(test_la ? test_la : 0),
              test_map: new UntypedFormControl(test_map ? test_map : 0),
              test_manual: new UntypedFormControl(test_manual ? test_manual : 0),
              test_virtual: new UntypedFormControl(test_virtual ? test_virtual : 0),
              test_result: new UntypedFormControl(test_result ? test_result : 0),
              test_event: new UntypedFormControl(test_event ? test_event : 0),
              region_name: new UntypedFormControl(region.name),
              region_id: new UntypedFormControl(region.value),
              type_name: new UntypedFormControl(this.getPriceType(i)),
              type_value: new UntypedFormControl(i),
            });
            this.getLotPrices(j).push(lotPrice);
          }
        }
      }
    }
  }

  public addPricingLot(region: any): void {
    const index: number = this.link_items.length;
    const uniqueRegionIdList: Array<number> = [];

    for (let j = 0; j < index; j++) {
      this.getPricingLotById(j).value.lots.forEach((item: any, index: number): void => {
        item.lot_prices.forEach((lotPrice: any): void => {
          if (uniqueRegionIdList.indexOf(lotPrice.region_id) === -1) {
            uniqueRegionIdList.push(lotPrice.region_id);
          }
        });

        if (uniqueRegionIdList.indexOf(region.region_id) === -1) {
          for (let i = 1; i < 5; i++) {
            const lotPrice: UntypedFormGroup = this.fb.group({
              link_item: new UntypedFormControl(0),
              region_name: new UntypedFormControl(region.name),
              region_id: new UntypedFormControl(region.value),
              type_name: new UntypedFormControl(this.getLinkPriceType(i)),
              type_value: new UntypedFormControl(i),
            });

            const linkItems: UntypedFormArray = this.pricingForm.get('link_items') as UntypedFormArray;
            const lots: UntypedFormArray = linkItems.at(j).get('lots') as UntypedFormArray;
            const lotPrices: UntypedFormArray = lots.at(index).get('lot_prices') as UntypedFormArray;
            lotPrices.push(lotPrice);
          }
        }
        });
      }
  }

  public prepareExcessItems(): UntypedFormArray {
    const lots = this.data.plan.lots;
    const lotsFormsArray: UntypedFormArray = this.fb.array([]);

    lots.map((lot: any): void => {
      const formGroupControls: Record<string, any> = {
        from_link_item: new UntypedFormControl(lot.from_link_item),
        from_user: new UntypedFormControl(lot.from_user),
        from_athlete: new UntypedFormControl(lot.from_athlete),
        from_test_la: new UntypedFormControl(lot.from_test_la),
        from_test_map: new UntypedFormControl(lot.from_test_map),
        from_test_manual: new UntypedFormControl(lot.from_test_manual),
        from_test_virtual: new UntypedFormControl(lot.from_test_virtual),
        from_test_event: new UntypedFormControl(lot.from_test_event),
        from_test_result: new UntypedFormControl(lot.from_test_result),
        to_link_item: new UntypedFormControl(lot.to_link_item),
        to_user: new UntypedFormControl(lot.to_user),
        to_athlete: new UntypedFormControl(lot.to_athlete),
        to_test_la: new UntypedFormControl(lot.to_test_la),
        to_test_map: new UntypedFormControl(lot.to_test_map),
        to_test_manual: new UntypedFormControl(lot.to_test_manual),
        to_test_virtual: new UntypedFormControl(lot.to_test_virtual),
        to_test_event: new UntypedFormControl(lot.to_test_event),
        to_test_result: new UntypedFormControl(lot.to_test_result),
        is_once_link_item: new UntypedFormControl(lot.is_once_link_item),
        is_once_user: new UntypedFormControl(lot.is_once_user),
        is_once_athlete: new UntypedFormControl(lot.is_once_athlete),
        is_once_la: new UntypedFormControl(lot.is_once_la),
        is_once_map: new UntypedFormControl(lot.is_once_map),
        is_once_manual: new UntypedFormControl(lot.is_once_manual),
        is_once_virtual: new UntypedFormControl(lot.is_once_virtual),
        is_once_event: new UntypedFormControl(lot.is_once_event),
        is_once_result: new UntypedFormControl(lot.is_once_result),
        lot_prices: this.prepareLotPrices(lot),
      };
      if (lot.id) {
        formGroupControls["id"] = new UntypedFormControl(lot.id);
      }
      const formGroup: UntypedFormGroup = this.fb.group(formGroupControls);
      lotsFormsArray.push(formGroup);
    });

    return lotsFormsArray;
  }

  public prepareLotPrices(item: any): UntypedFormArray {
    this.isShowExcessRegions = true;
    const newArray: UntypedFormArray = new UntypedFormArray([]);
    item.lot_prices.map((price: any): void => {
      const lotPriceControls: Record<string, any> = {
        user: new UntypedFormControl(price.user),
        athlete: new UntypedFormControl(price.athlete),
        test_la: new UntypedFormControl(price.test_la),
        test_map: new UntypedFormControl(price.test_map),
        test_manual: new UntypedFormControl(price.test_manual),
        test_virtual: new UntypedFormControl(price.test_virtual),
        test_result: new UntypedFormControl(price.test_result),
        test_event: new UntypedFormControl(price.test_event),
        region_name: new UntypedFormControl(
          this.regionOptions.find(
            (region: any): boolean => region.value == price.region
          ).name
        ),
        region_id: new UntypedFormControl(price.region),
        type_name: new UntypedFormControl(this.getPriceType(price.type)),
        type_value: new UntypedFormControl(price.type),
      };
      if (price.id) {
        lotPriceControls["id"] = new UntypedFormControl(price.id);
      }
      const lotPrice: UntypedFormGroup = this.fb.group(lotPriceControls);
      newArray.push(lotPrice);
    });

    return newArray;
  }

  public get lots_excess(): UntypedFormArray {
    return this.excessForm.get('lots_excess') as UntypedFormArray;
  }

  public removeExcess(index: number): void {
    const i: number = this.lots_excess.length - 1;
    if (index < i) {
      this.snackBar.open('Please remove the last one first.', 'OK', this.constant.TOAST_CONFIG.ERROR);
    } else {
      this.lots_excess.removeAt(index);
    }
  }

  public getMaxValue(input: any) {
    if (this.isAccumulated) {
      const allValuesSet = this.feeForm.get("billing_period_details")?.value.every((detail: any): boolean => detail[input] != null);
      return allValuesSet ? true : null;
    }

    return this.feeForm.get(input)?.value;
  }

  public getToFromValue() {
    const index: number = this.excessForm.get('lots_excess')?.value.length - 1;

    return this.excessForm.get('lots_excess')?.value[index];
  }

  public addExcessForm(): void {
    const length = this.feeForm.get('include_items')?.value.length;
    const lengthLots = this.excessForm.get('lots_excess')?.value.length;

    if (length == 0) {
      this.snackBar.open('Please add at least one item.', 'OK', this.constant.TOAST_CONFIG.ERROR);
    } else {
      if (length != 0 && this.isShowItemUser && this.getMaxValue('max_user') == null) {
        this.fillInfoMessage();
      } else if (length != 0 && this.isShowItemAthlete && this.getMaxValue('max_athlete') == null) {
        this.fillInfoMessage();
      } else if (length != 0 && this.isShowItemLA && this.getMaxValue('max_test_la') == null) {
        this.fillInfoMessage();
      } else if (length != 0 && this.isShowItemMap && this.getMaxValue('max_test_map') == null) {
        this.fillInfoMessage();
      } else if (length != 0 && this.isShowItemManual && this.getMaxValue('max_test_manual') == null) {
        this.fillInfoMessage();
      } else if (length != 0 && this.isShowItemVirtual && this.getMaxValue('max_test_virtual') == null) {
        this.fillInfoMessage();
      } else if (length != 0 && this.isShowItemEvent && this.getMaxValue('max_test_event') == null) {
        this.fillInfoMessage();
      } else if (length != 0 && this.isShowItemResult && this.getMaxValue('max_test_result') == null) {
        this.fillInfoMessage();
      } else if (lengthLots >= 1 && this.isShowItemUser && (this.getToFromValue().to_user == 0 || this.getToFromValue().to_user <= this.getToFromValue().from_user)) {
        this.fillGreaterMessage();
      } else if (lengthLots >= 1 && this.isShowItemAthlete && (this.getToFromValue().to_athlete == 0 || this.getToFromValue().to_athlete <= this.getToFromValue().from_athlete)) {
        this.fillGreaterMessage();
      } else if (lengthLots >= 1 && this.isShowItemLA && (this.getToFromValue().to_test_la == 0 || this.getToFromValue().to_test_la <= this.getToFromValue().from_test_la)) {
        this.fillGreaterMessage();
      } else if (lengthLots >= 1 && this.isShowItemMap && (this.getToFromValue().to_test_map == 0 || this.getToFromValue().to_test_map <= this.getToFromValue().from_test_map)) {
        this.fillGreaterMessage();
      } else if (lengthLots >= 1 && this.isShowItemManual && (this.getToFromValue().to_test_manual == 0 || this.getToFromValue().to_test_manual <= this.getToFromValue().from_test_manual)) {
        this.fillGreaterMessage();
      } else if (lengthLots >= 1 && this.isShowItemVirtual && (this.getToFromValue().to_test_virtual == 0 || this.getToFromValue().to_test_virtual <= this.getToFromValue().from_test_virtual)) {
        this.fillGreaterMessage();
      } else if (lengthLots >= 1 && this.isShowItemEvent && (this.getToFromValue().to_test_event == 0 || this.getToFromValue().to_test_event <= this.getToFromValue().from_test_event)) {
        this.fillGreaterMessage();
      } else if (lengthLots >= 1 && this.isShowItemResult && (this.getToFromValue().to_test_result == 0 || this.getToFromValue().to_test_result <= this.getToFromValue().from_test_result)) {
        this.fillGreaterMessage();
      } else {
        this.isSelectedItem();
        this.preparedLotForm();
        this.addLotPriceField();
      }
    }
  }

  private getMaxFromFeeForm(item: string): number {
    if (this.isAccumulated) {
      const annualItems = this.feeForm.get("billing_period_details")?.value.find((detail: any) => detail.period_type === 1);
      return annualItems[item];
    }
    return this.feeForm.get(item)?.value;
  }

  public isSelectedItem(): void {
    for (let i of this.feeForm.get('include_items')?.value) {
      if (this.excessForm.get('lots_excess')?.value.length == 0) {
        if (i.id == 1) {
          this.from_user = this.getMaxFromFeeForm('max_user') + 1;
        } else if (i.id == 2) {
          this.from_athlete = this.getMaxFromFeeForm('max_athlete') + 1;
        } else if (i.id == 3) {
          this.from_test_la = this.getMaxFromFeeForm('max_test_la') + 1;
        } else if (i.id == 4) {
          this.from_test_map = this.getMaxFromFeeForm('max_test_map') + 1;
        } else if (i.id == 5) {
          this.from_test_manual = this.getMaxFromFeeForm('max_test_manual') + 1;
        } else if (i.id == 6) {
          this.from_test_virtual = this.getMaxFromFeeForm('max_test_virtual') + 1;
        } else if (i.id == 7) {
          this.from_test_event = this.getMaxFromFeeForm('max_test_event') + 1;
        } else if (i.id == 8) {
          this.from_test_result = this.getMaxFromFeeForm('max_test_result') + 1;
        }
      } else {
        const index: number = this.excessForm.get('lots_excess')?.value.length - 1;
        if (i.id == 1) {
          this.from_user = this.excessForm.get('lots_excess')?.value[index].to_user + 1;
        } else if (i.id == 2) {
          this.from_athlete = this.excessForm.get('lots_excess')?.value[index].to_athlete + 1;
        } else if (i.id == 3) {
          this.from_test_la = this.excessForm.get('lots_excess')?.value[index].to_test_la + 1;
        } else if (i.id == 4) {
          this.from_test_map = this.excessForm.get('lots_excess')?.value[index].to_test_map + 1;
        } else if (i.id == 5) {
          this.from_test_manual = this.excessForm.get('lots_excess')?.value[index].to_test_manual + 1;
        } else if (i.id == 6) {
          this.from_test_virtual = this.excessForm.get('lots_excess')?.value[index].to_test_virtual + 1;
        } else if (i.id == 7) {
          this.from_test_event = this.excessForm.get('lots_excess')?.value[index].to_test_event + 1;
        } else if (i.id == 8) {
          this.from_test_result = this.excessForm.get('lots_excess')?.value[index].to_test_result + 1;
        }
      }
    }
  }

  public preparedLotForm(): void {
    const lot: UntypedFormGroup = this.fb.group({
      from_link_item: new UntypedFormControl(0),
      from_user: new UntypedFormControl(this.from_user),
      from_athlete: new UntypedFormControl(this.from_athlete),
      from_test_la: new UntypedFormControl(this.from_test_la),
      from_test_map: new UntypedFormControl(this.from_test_map),
      from_test_manual: new UntypedFormControl(this.from_test_manual),
      from_test_virtual: new UntypedFormControl(this.from_test_virtual),
      from_test_event: new UntypedFormControl(this.from_test_event),
      from_test_result: new UntypedFormControl(this.from_test_result),
      to_link_item: new UntypedFormControl(0),
      to_user: new UntypedFormControl(null),
      to_athlete: new UntypedFormControl(null),
      to_test_la: new UntypedFormControl(null),
      to_test_map: new UntypedFormControl(null),
      to_test_manual: new UntypedFormControl(null),
      to_test_virtual: new UntypedFormControl(null),
      to_test_event: new UntypedFormControl(null),
      to_test_result: new UntypedFormControl(null),
      is_once_link_item: new UntypedFormControl(false),
      is_once_user: new UntypedFormControl(false),
      is_once_athlete: new UntypedFormControl(false),
      is_once_la: new UntypedFormControl(false),
      is_once_map: new UntypedFormControl(false),
      is_once_manual: new UntypedFormControl(false),
      is_once_virtual: new UntypedFormControl(false),
      is_once_event: new UntypedFormControl(false),
      is_once_result: new UntypedFormControl(false),
      lot_prices: this.fb.array([]),
    });
    this.lots_excess.push(lot);
  }

  public get ratios(): UntypedFormArray {
    return this.ratiosForm.get('ratios') as UntypedFormArray;
  }

  public removeRatio(index: number): void {
    this.ratios.removeAt(index);
  }

  public addRatioForm(): void {
    const ratio: UntypedFormGroup = this.fb.group({
      item1: new UntypedFormControl(''),
      item2: new UntypedFormControl(''),
      item1_quantity: new UntypedFormControl(0),
      period_value: new UntypedFormControl(''),
      period_type: new UntypedFormControl(''),
      item2_quantity: new UntypedFormControl(1),
    });
    this.ratios.push(ratio);
  }

  public prepareRatios(): UntypedFormArray {
    const ratios = this.data.plan.ratios;
    const ratioFormsArray: UntypedFormArray = this.fb.array([]);

    ratios.map((ratio: any): void => {
      const formGroupControls: Record<string, any> = {
        item1: new UntypedFormControl(ratio.item1),
        item2: new UntypedFormControl(ratio.item2),
        item1_quantity: new UntypedFormControl(ratio.item1_quantity),
        period_value: new UntypedFormControl(ratio.period_value),
        period_type: new UntypedFormControl(ratio.period_type),
        item2_quantity: new UntypedFormControl(ratio.item2_quantity),
      };
      if (ratio.id) {
        formGroupControls["id"] = new UntypedFormControl(ratio.id);
      }
      const formGroup: UntypedFormGroup = this.fb.group(formGroupControls);
      ratioFormsArray.push(formGroup);
    });

    return ratioFormsArray;
  }

  public onSelectDiscAccountType(value: any, index: number): void {
    this.getFormControlFromArray('account_types', index)?.setValue(value);
  }

  public onSelectDiscRegion(value: any, index: number): void {
    this.getFormControlFromArray('regions', index)?.setValue(value);
  }

  public onSelectDiscOrganization(value: any, index: number): void {
    this.getFormControlFromArray('organizations', index)?.setValue(value);
  }

  public get discounts(): UntypedFormArray {
    return this.discountsForm.get('discounts') as UntypedFormArray;
  }

  public removeDiscountField(index: number): void {
    this.discounts.removeAt(index);
  }

  public addDiscountForm(): void {
    const discount = this.fb.group({
      discount_value: new UntypedFormControl('', [Validators.required]),
      discount_type: new UntypedFormControl(1, [Validators.required]),
      purchased_day: new UntypedFormControl('', [Validators.required]),
      account_types: new UntypedFormControl([]),
      regions: new UntypedFormControl([]),
      organizations: new UntypedFormControl([]),
      valid_until: new UntypedFormControl(new Date()),
    });
    this.discounts.push(discount);
  }

  public copyDiscountForm(discount: any): void {
    const discountCopy = _.cloneDeep(discount);
    this.discounts.push(discountCopy);
  }

  public prepareDiscounts(): UntypedFormArray {
    const discounts = this.data.plan.discounts;
    const discountFormsArray: UntypedFormArray = this.fb.array([]);

    discounts.map((discount: any): void => {
      const formGroupControls: Record<string, any> = {
        discount_value: new UntypedFormControl(discount.discount_value),
        discount_type: new UntypedFormControl(discount.discount_type),
        purchased_day: new UntypedFormControl(discount.purchased_day),
        account_types: new UntypedFormControl(this.prepareData(discount.account_types, this.accountOptions)),
        regions: new UntypedFormControl(this.prepareData(discount.regions, this.regionOptions)),
        organizations: new UntypedFormControl(this.prepareData(discount.organizations, this.organizationOptions)),
        valid_until: new UntypedFormControl(discount.valid_until),
      };
      if (discount.id) {
        formGroupControls["id"] = new UntypedFormControl(discount.id)
      }
      const formGroup: UntypedFormGroup = this.fb.group(formGroupControls);
      discountFormsArray.push(formGroup);
    });

    return discountFormsArray;
  }

  public prepareDurationDetails(isNew: boolean = false): UntypedFormArray {
    const durationDetailsFormsArray: UntypedFormArray = this.fb.array([]);
    let period_types: number[] = [1, 2, 3];

    if (!isNew) {
      const billingPeriodDetails = this.data.plan.billing_period_details;
      billingPeriodDetails.map((billingPeriodDetail: BillingPeriodDetail): void => {
        const formGroupControls: Record<string, any> = {
          renewal: new UntypedFormControl(billingPeriodDetail.renewal),
          renewal_unit: new UntypedFormControl(billingPeriodDetail.renewal_unit),
          cancel_notice_time: new UntypedFormControl(billingPeriodDetail.cancel_notice_time),
          minimum_contract_duration: new UntypedFormControl(billingPeriodDetail.minimum_contract_duration),
          period_type: new UntypedFormControl(billingPeriodDetail.period_type),
        };
        if (billingPeriodDetail.id) {
          formGroupControls["id"] = new UntypedFormControl(billingPeriodDetail.id);
        }
        const formGroup: UntypedFormGroup = this.fb.group(formGroupControls);
        durationDetailsFormsArray.push(formGroup);
        period_types.splice(period_types.findIndex(period_type => period_type === billingPeriodDetail.period_type), 1);
      });
    }

    // We must always have all 3 tabs, so any missing tabs need to be added with default values
    period_types.map((period_type: number): void => {
      const formGroupControls: Record<string, any> = {
        renewal: new UntypedFormControl(),
        renewal_unit: new UntypedFormControl(period_type),
        cancel_notice_time: new UntypedFormControl(0),
        minimum_contract_duration: new UntypedFormControl(),
        period_type: new UntypedFormControl(period_type),
      }
      const formGroup: UntypedFormGroup = this.fb.group(formGroupControls);
      durationDetailsFormsArray.push(formGroup);
    });

    const durationDetailControlValues: Array<Record<string, any>> = durationDetailsFormsArray.value;
    durationDetailControlValues.sort((value: Record<string, any>) => value.period_type).reverse();
    durationDetailsFormsArray.patchValue(durationDetailControlValues);

    return durationDetailsFormsArray;
  }

  public prepareItemCounts(isNew: boolean = false): UntypedFormArray {
    const billingPeriodDetailsFormsArray: UntypedFormArray = this.fb.array([]);
    let period_types: number[] = [1, 2, 3];

    if (!isNew) {
      const billingPeriodDetails = this.data.plan.billing_period_details;
      billingPeriodDetails.map((billingPeriodDetail: any): void => {
        const formGroupControls: Record<string, any> = {
          max_athlete: new UntypedFormControl(billingPeriodDetail.max_athlete),
          max_test_event: new UntypedFormControl(billingPeriodDetail.max_test_event),
          max_test_la: new UntypedFormControl(billingPeriodDetail.max_test_la),
          max_test_manual: new UntypedFormControl(billingPeriodDetail.max_test_manual),
          max_test_map: new UntypedFormControl(billingPeriodDetail.max_test_map),
          max_test_result: new UntypedFormControl(billingPeriodDetail.max_test_result),
          max_test_virtual: new UntypedFormControl(billingPeriodDetail.max_test_virtual),
          max_user: new UntypedFormControl(billingPeriodDetail.max_user),
          period_type: new UntypedFormControl(billingPeriodDetail.period_type),
        };
        if (billingPeriodDetail.id) {
          formGroupControls["id"] = new UntypedFormControl(billingPeriodDetail.id)
        }
        const formGroup: UntypedFormGroup = this.fb.group(formGroupControls);
        billingPeriodDetailsFormsArray.push(formGroup);
        period_types.splice(period_types.findIndex(period_type => period_type === billingPeriodDetail.period_type), 1);
      });
    }

    // We must always have all 3 rows, so any missing rows need to be added with default values
    period_types.map((period_type: number): void => {
      const formGroupControls: Record<string, any> = {
        max_athlete: new UntypedFormControl(),
        max_test_event: new UntypedFormControl(),
        max_test_la: new UntypedFormControl(),
        max_test_manual: new UntypedFormControl(),
        max_test_map: new UntypedFormControl(),
        max_test_result: new UntypedFormControl(),
        max_test_virtual: new UntypedFormControl(),
        max_user: new UntypedFormControl(),
        period_type: new UntypedFormControl(period_type),
      };
      const formGroup: UntypedFormGroup = this.fb.group(formGroupControls);
      billingPeriodDetailsFormsArray.push(formGroup);
    });

    const billingPeriodDetailControlValues: Array<Record<string, any>> = billingPeriodDetailsFormsArray.value;
    billingPeriodDetailControlValues.sort(value => value.period_type);
    billingPeriodDetailsFormsArray.patchValue(billingPeriodDetailControlValues);

    return billingPeriodDetailsFormsArray;
  }

  public getBillingDetailPeriodName(value: number): string {
    const name: string = AppConstants.PERIOD_TYPES.find((period: any): boolean => period.id === value)?.name ?? "Annual";

    // For consistency with the prices control
    return name === "Year" ? "Annual" : name;
  }

  public getFormControlFromArray(controlName: string, index: number): AbstractControl<any, any> | null {
    return (this.discountsForm.get('discounts') as UntypedFormArray).controls[index].get(controlName);
  }

  public onSelectProduct(value: any): void {
    this.firstForm.get('product_ids')?.setValue(value);
  }

  public onSelectFeature(value: any): void {
    this.firstForm.get('feature_ids')?.setValue(value);
  }

  public onSelectAccount(value: any): void {
    this.availabilityForm.get('account_types')?.setValue(value);
  }

  public onSelectOrganization(value: any): void {
    this.availabilityForm.get('organizations')?.setValue(value);
  }

  public onSelectItemDisplay(value: any): void {
    if (value.length == 3) {
      this.itemDisplayForm.get('display_items')?.setValue(value);
      this.itemDisplayOptions = [];
    } else {
      this.itemDisplayForm.get('display_items')?.setValue(value);
      this.itemDisplayOptions = AppConstants.ITEMS;
    }
  }

  public onSelectItemIncluded(value: any): void {
    this.feeForm.get('include_items')?.setValue(value);
    this.bundledOptions = this.feeForm.get('include_items')?.value;

    this.isShowItemUser = false;
    this.isShowItemAthlete = false;
    this.isShowItemLA = false;
    this.isShowItemMap = false;
    this.isShowItemManual = false;
    this.isShowItemVirtual = false;
    this.isShowItemEvent = false;
    this.isShowItemResult = false;
    this.isShowItemMetabolic = false;
    this.isShowItemReports = false;

    for (let i of value) {
      if (i.id == 1) {
        this.isShowItemUser = true;
      } else if (i.id == 2) {
        this.isShowItemAthlete = true;
      } else if (i.id == 3) {
        this.isShowItemLA = true;
      } else if (i.id == 4) {
        this.isShowItemMap = true;
      } else if (i.id == 5) {
        this.isShowItemManual = true;
      } else if (i.id == 6) {
        this.isShowItemVirtual = true;
      } else if (i.id == 7) {
        this.isShowItemEvent = true;
      } else if (i.id == 8) {
        this.isShowItemResult = true;
      } else if (i.id == 8) {
        this.isShowItemMetabolic = true;
      } else if (i.id == 8) {
        this.isShowItemReports = true;
      }
    }
  }

  public save(): void {
    const lengthLots = this.excessForm.get('lots_excess')?.value.length;
    const hasLinkedItemLots = this.hasItemsLots();

    if (!lengthLots) {
      this.snackBar.open('Please create at least one lot excess item to proceed.', 'OK', this.constant.TOAST_CONFIG.ERROR);

      return;
    } else if (!hasLinkedItemLots) {
      this.snackBar.open('Please create at least one lot for linked item to proceed.', 'OK', this.constant.TOAST_CONFIG.ERROR);

      return;
    }

    const plan: PricingPlansDto = new PricingPlansDto();

    plan.name = this.firstForm.get('name')?.value;
    plan.is_publish = this.firstForm.get('is_publish')?.value;
    plan.is_accumulated = this.firstForm.get('is_accumulated')?.value;
    plan.group = this.firstForm.get('group')?.value;
    plan.product_ids = this.firstForm.get('product_ids')?.value ? this.firstForm.get('product_ids')?.value.map((product: any) => product.value).join('|') : '';
    plan.bundled_features = this.firstForm.get('feature_ids')?.value.length ? this.firstForm.get('feature_ids')?.value.map((item: any) => item.value) : [];
    const right_management = this.firstForm.get('rightManagement')?.value;
    if (typeof right_management === 'string') {
      plan.right_management = right_management;
    } else {
      plan.right_management = JSON.stringify(right_management);
    }
    plan.is_show_setting = this.firstForm.get('is_show_setting')?.value;
    plan.description = this.planForm.get('description')?.value;
    plan.features = this.planForm.get('features')?.value.map((feature: any) => feature.feature).join('|');
    plan.account_types = this.prepareArraysForSave(this.availabilityForm, 'account_types');
    plan.regions = this.prepareArraysForSave(this.availabilityForm, 'regions');
    plan.organizations = this.prepareArraysForSave(this.availabilityForm, 'organizations');
    plan.renewal = this.durationForm.get('renewal')?.value;
    plan.renewal_unit = this.durationForm.get('renewal_unit')?.value;
    plan.cancel_notice_time = this.durationForm.get('cancel_notice_time')?.value;
    plan.minimum_contract_duration = this.durationForm.get('minimum_contract_duration')?.value;
    plan.include_items = this.feeForm.get('include_items')?.value ? this.feeForm.get('include_items')?.value.map((item: any) => item.id).join('|') : '';
    plan.max_user = this.feeForm.get('max_user')?.value;
    plan.max_athlete = this.feeForm.get('max_athlete')?.value;
    plan.max_test_la = this.feeForm.get('max_test_la')?.value;
    plan.max_test_map = this.feeForm.get('max_test_map')?.value;
    plan.max_test_manual = this.feeForm.get('max_test_manual')?.value;
    plan.max_test_virtual = this.feeForm.get('max_test_virtual')?.value;
    plan.max_test_event = this.feeForm.get('max_test_event')?.value;
    plan.max_test_result = this.feeForm.get('max_test_result')?.value;
    plan.plan_prices = this.feeForm
      .get('plan_prices')
      ?.value.map((price: any) => {
        const values: Record<string, any> = {
          region: price.region_id,
          annual: price.annual,
          quarter: price.quarter,
          month: price.month
        };
        if (price.id) {
          values["id"] = price.id
        }
        return values;
      });

    if (this.isAccumulated) {
      // Set up item counts
      plan.billing_period_details = this.feeForm.get('billing_period_details')?.value.map((detail: BillingPeriodDetail) => {
        const durationDetail = this.durationForm.get('billing_period_details')?.value.find(
          (durationDetail: BillingPeriodDetail) => durationDetail.period_type === detail.period_type)
        const values: Record<string, any> = {
          max_athlete: detail.max_athlete,
          max_test_event: detail.max_test_event,
          max_test_la: detail.max_test_la,
          max_test_manual: detail.max_test_manual,
          max_test_map: detail.max_test_map,
          max_test_result: detail.max_test_result,
          max_test_virtual: detail.max_test_virtual,
          max_user: detail.max_user,
          period_type: detail.period_type,
          renewal: durationDetail.renewal,
          renewal_unit: durationDetail.renewal_unit,
          cancel_notice_time: durationDetail.cancel_notice_time,
          minimum_contract_duration: durationDetail.minimum_contract_duration,
        };

        if (detail.id) {
          values["id"] = detail.id;
        }

        // Annual duration details get assigned to the plan as well (if they
        // are not already assigned)
        if (durationDetail.period_type === 1) {
          plan.renewal = plan.renewal ?? durationDetail.renewal;
          plan.renewal_unit = plan.renewal_unit ?? durationDetail.renewal_unit;
          plan.cancel_notice_time = plan.cancel_notice_time ?? durationDetail.cancel_notice_time;
          plan.minimum_contract_duration = plan.minimum_contract_duration ?? durationDetail.minimum_contract_duration;
        }

        return values;
      });
    } else {
      plan.billing_period_details = plan.billing_period_details ?? [];
    }

    const alternativeItems = JSON.parse(JSON.stringify(this.feeForm.get('alternative_items')?.value));
    plan.alternative_items = alternativeItems
      ? alternativeItems.map((item: any) => {
        item.alternative_item = item.alternative_item
          ? item.alternative_item.map((i: any) => i.id).join('|')
          : '';
        return item;
      })
      : [];

    plan.display_items = this.itemDisplayForm.get('display_items')?.value
      ? this.itemDisplayForm
        .get('display_items')
        ?.value.map((item: any) => item.id)
        .join('|')
      : '';
    plan.ratios = this.ratiosForm.get('ratios')?.value;
    plan.discounts = this.discountsForm
      .get('discounts')
      ?.value.map((discount: any) => {
        discount.account_types = discount.account_types.map(
          (account_type: any) => account_type.value
        );
        discount.regions = discount.regions.map((region: any) => region.value);
        discount.organizations = discount.organizations.map(
          (organization: any) => organization.value
        );
        return discount;
      });

    plan.lots = this.excessForm.get('lots_excess')?.value.map((lot: any) => {
      lot.lot_prices = lot.lot_prices.map((price: any) => {
        const values: Record<string, any> = {
          region: price.region_id,
          type: price.type_value,
          user: price.user,
          athlete: price.athlete,
          test_event: price.test_event,
          test_manual: price.test_manual,
          test_la: price.test_la,
          test_map: price.test_map,
          test_result: price.test_result,
          test_virtual: price.test_virtual,
        };

        if (price.id) {
          values["id"] = price.id;
        }

        return values;
      });

      return lot;
    });

    plan.notification_athlete = this.thresholdForm.get('notification_athlete')?.value;
    plan.notification_test_event = this.thresholdForm.get('notification_test_event')?.value;
    plan.notification_test_la = this.thresholdForm.get('notification_test_la')?.value;
    plan.notification_test_manual = this.thresholdForm.get('notification_test_manual')?.value;
    plan.notification_test_map = this.thresholdForm.get('notification_test_map')?.value;
    plan.notification_test_result = this.thresholdForm.get('notification_test_result')?.value;
    plan.notification_test_virtual = this.thresholdForm.get('notification_test_virtual')?.value;
    plan.notification_user = this.thresholdForm.get('notification_user')?.value;

    this.pricingForm.get('link_items')?.value;

    plan.link_items = this.pricingForm.get('link_items')?.value.map((item: any) => {
        item.activation_item = item.activation_item ? item.activation_item.map((a: any) => a.id).join('|') : '';
        item.lots = item.lots.map((lot: any) => {
          const mappedLot: Record<string, any> = {
            from_link_item: lot.from_link_item,
            to_link_item: lot.to_link_item,
            is_once_link_item: lot.is_once_link_item,
            lot_prices: lot.lot_prices.map((price: any) => {
              const mappedPrice: Record<string, any> = {
                region: price.region_id,
                type: price.type_value,
                link_item: price.link_item,
              };

              if (price?.id) {
                mappedPrice.id = price.id;
              }

              return mappedPrice;
            }),
          };

          if (lot?.id) {
            mappedLot.id = lot.id;
          }

          return mappedLot;
        });

        return item;
      });

    if (!this.data.isEdit) {
      this.planService
        .create(plan)
        .pipe(
          catchError((error) => {
            this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);

            throw error;
          }),
          finalize((): boolean => (this.isDataLoading = false))
        )
        .subscribe((res: PricingPlansDto) => this.dialogRef.close(res));
    } else {
      this.planService
        .update(this.data.plan.id, plan)
        .pipe(
          catchError((error) => {
            this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
            throw error;
          }),
          finalize((): boolean => (this.isDataLoading = false))
        )
        .subscribe((res: PricingPlansDto) => this.dialogRef.close(res));
    }
  }

  public prepareArraysForSave(form: UntypedFormGroup, fieldName: string) {
    if (form.get(fieldName)?.value) {
      return form.get(fieldName)?.value.map((item: any) => item.value);
    } else {
      return [];
    }
  }

  public prepareInputs(): void {
    if (this.data.plan.plan_prices.length != 0) {
      this.isShowColumns = true;
    }
    const include_items = this.data.plan.include_items?.split('|');
    for (let i of include_items) {
      if (i == 1) {
        this.isShowItemUser = true;
      } else if (i == 2) {
        this.isShowItemAthlete = true;
      } else if (i == 3) {
        this.isShowItemLA = true;
      } else if (i == 4) {
        this.isShowItemMap = true;
      } else if (i == 5) {
        this.isShowItemManual = true;
      } else if (i == 6) {
        this.isShowItemVirtual = true;
      } else if (i == 7) {
        this.isShowItemEvent = true;
      } else if (i == 8) {
        this.isShowItemResult = true;
      } else if (i == 8) {
        this.isShowItemMetabolic = true;
      } else if (i == 8) {
        this.isShowItemReports = true;
      }
    }
  }

  public onChangeInputFee(event: any, field: any): void {
    if (this.excessForm.get('lots_excess')?.value.length != 0) {
      this.lots_excess.controls[0].get(field)?.setValue(parseInt(event.target.value) + 1);
    }
  }

  public onChangeExcessInput(event: any, field: any, index: any): void {
    if (this.excessForm.get('lots_excess')?.value.length > 1) {
      this.lots_excess.controls[index + 1]?.get(field)?.setValue(parseInt(event.target.value) + 1);
    }
  }

  fillInfoMessage(): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open('Please fill out the information', 'OK', this.constant.TOAST_CONFIG.ERROR);
  }

  public fillGreaterMessage(): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open('Please fill out the previous lot and make sure TO must be greater than FROM.', 'OK', this.constant.TOAST_CONFIG.ERROR);
  }

  public get isAccumulated() {
    return this.firstForm.get("is_accumulated")?.value ?? false;
  }

  public openCloseRights(event: any): void {
    this.firstForm.patchValue({ is_show_setting: event });

    this.showRightManagement = !!this.firstForm.get('is_show_setting')?.value;
  }

  public changedValueOfRadioButton(path: string, event: any): void {
    this.rights[path] = event.value;
    this.firstForm.get('rightManagement')?.setValue(this.rights);
  }

  public isRadioChecked(path: string, value: any): boolean {
    return this.rights[path] === value;
  }

  public resetRights(): void {
    const keys: string[] = Object.keys(this.rights);
    keys.map((key: string): void => {
      this.rights[key] = false;
    });
  }
}
