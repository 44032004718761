<div class="page-container">
  <div class="my-profile__common-info">
    <div class="sub-title">PROFILE SETTINGS</div>
    <div class="o-flex o-flex--justify-space-between o-flex--align-items-center">
      <span class="general-info--font-size-35 line-height-40">Profile</span>
    </div>
    <span class="u-font-weight-bold u-font-size-16 u-margin-bottom-20 u-margin-top-10 u-display-block">Quick access feature</span>
  </div>
  <div class="o-flex o-flex--justify-center o-flex--wrap u-margin-top-20 my-profile__item-container">
    <app-profile-item *ngFor="let profileItem of profileItemList" class="my-profile__item u-cursor-pointer u-display-inline-block u-margin-right-10 u-margin-bottom-20" [config]="profileItem"></app-profile-item>
  </div>
</div>
