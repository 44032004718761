import { Component, DestroyRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '@core/constants';
import { AuthService } from "@core/services/auth/auth.service";
import { NgZone } from "@angular/core";
import { GoogleTagManagerHelperService } from "@shared/services/google-tag-manager-helper.service";
import { IAuth } from "@modules/login/login.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

const globalThis = window;

@Component({
  selector: 'app-force-update-consent',
  templateUrl: './force-update-consent.component.html',
  styleUrls: ['./force-update-consent.component.scss']
})
export class ForceUpdateConsentComponent implements OnInit {
  public form!: UntypedFormGroup;
  public isSessionExpired: boolean = false;
  public isConsentFormAccessible: boolean = false;
  public isLoading: boolean = false;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly snackBar: MatSnackBar,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    private googleTagManagerHelperService: GoogleTagManagerHelperService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this.isSessionExpired = this.activatedRoute.snapshot.queryParams.expired_session;
    this.initForm();
  }

  public initForm(): void {
    if (globalThis.FORCE_UPDATE_CONSENT) {
      this.isConsentFormAccessible = true;
      const ControlsConfig = {
        subjectId: new UntypedFormControl(globalThis.FORCE_UPDATE_CONSENT.subject_id),
        subjectFirstName: new UntypedFormControl(globalThis.FORCE_UPDATE_CONSENT.subject_first_name),
        subjectLastName: new UntypedFormControl(globalThis.FORCE_UPDATE_CONSENT.subject_last_name),
        fullName: new UntypedFormControl(''),
        subjectEmail: new UntypedFormControl(globalThis.FORCE_UPDATE_CONSENT.subject_email),
        legalNotices: [false, Validators.requiredTrue],
      };
      this.form = this.fb.group(ControlsConfig);
    }
  }

  public sendToIubenda(e: any): void {
    this.isLoading = true;
    e.preventDefault();
    window._iub.cons_instructions.push(["submit", {
      writeOnLocalStorage: false,
      form: {
        selector: document.getElementById('the-form'),
      },
      consent: {
        legal_notices: [
          {
            identifier: 'privacy_policy',
          },
          {
            identifier: 'term',
          }
        ],
      }
    },{
      success: (response: any): void => {
        if (response) {
          const loginData = {
            username: globalThis.FORCE_UPDATE_CONSENT.username,
            password: globalThis.FORCE_UPDATE_CONSENT.password,
          };

          this.authService.login(loginData)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((res: IAuth): void => {
              delete globalThis.FORCE_UPDATE_CONSENT;
              this.ngZone.run((): void => {
                this.isLoading = false;
                this.googleTagManagerHelperService.setDataLayer(res);
                this.authService.setAuthAndGoToMainPage(res, this.isSessionExpired);
              });
            },
            (error): void => {
              this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            });
        }
      },
      error: (err: any): void => {
        this.snackBar.open(err, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    }]);
  }

  public decline(): void {
    this.router.navigate(['/']);
  }

  public get fullName(): string {
    return `${this.form.get('subjectFirstName')?.value } ${this.form.get('subjectLastName')?.value }`;
  }

  public get subjectId(): string {
    return this.form.get('subjectId')?.value;
  }

  public get subjectEmail(): string {
    return this.form.get('subjectEmail')?.value;
  }

  public get isSubjectFirstNameReadOnly(): string {
    return globalThis.FORCE_UPDATE_CONSENT.subject_first_name;
  }

  public get isSubjectLastNameReadOnly(): string {
    return globalThis.FORCE_UPDATE_CONSENT.subject_last_name;
  }

  public get isSubjectEmailReadOnly(): string {
    return globalThis.FORCE_UPDATE_CONSENT.subject_email;
  }
}
